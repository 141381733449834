import { useEffect, useState } from 'react';

import { OrderItemStatus } from '@hcs/types';
import { InspectionViewState } from '@hcs/types';

export const useOmAdminOrderItemInspectionView = (status?: OrderItemStatus) => {
  const [defaultView, setDefaultView] = useState<InspectionViewState | null>(
    null
  );

  const handleToggleView = () => {
    setDefaultView((prev) => {
      return prev === 'pdf' ? 'form' : 'pdf';
    });
  };

  useEffect(() => {
    if (status) {
      if (
        status === OrderItemStatus.Complete ||
        status === OrderItemStatus.ValuationReview
      ) {
        setDefaultView('pdf');
      }

      if (
        status === OrderItemStatus.InspectionComplete ||
        status === OrderItemStatus.InspectionReview ||
        status === OrderItemStatus.InspectionCorrectionReview ||
        status === OrderItemStatus.InspectionRevision ||
        status === OrderItemStatus.ReportPending ||
        status === OrderItemStatus.RevisionRequested
      ) {
        setDefaultView('form');
      }
    }
  }, [status]);

  return { defaultView, handleToggleView };
};
