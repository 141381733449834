import React from 'react';
import classNames from 'classnames';

import { ENV } from '@hcs/webapps';

import styles from './AppEnvironmentDisplay.module.css';

export const AppEnvironmentDisplay = () => {
  return (
    <div
      className={classNames(styles.AppEnvironmentDisplay, {
        [styles.prod]: ENV === 'prod',
      })}
    >
      <span>{ENV}</span>
    </div>
  );
};
