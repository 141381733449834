import React, { useMemo } from 'react';

import { AutoComplete } from '@hcs/design-system';
import { OrderItemAssignee } from '@hcs/types';
import { formatMissing } from '@hcs/utils';

import { useAgileOpsAssignees } from '../../hooks/useAgileOpsAssignees';
import { sortedAssignees } from '../../utils';

import styles from '../AgileOpsOrderItemsAssign/AgileOpsOrderItemsAssign.module.css';

interface Props {
  className?: string;
  value?: number | null | 'multi';
  valueReportId?: number | null;
  onSelect: (assignee: OrderItemAssignee | null) => void;
}
const dataHcName = 'agile-ops-assignees-autocomplete';

const unassignedUser: OrderItemAssignee = {
  id: 0,
  firstName: 'Un',
  lastName: 'assigned',
  email: 'un@assigned.com',
  licenseState: null,
  licenseStates: null,
  organizationId: 0,
};

const getSearchString = (assignee: OrderItemAssignee): string => {
  return assignee.firstName || assignee.lastName
    ? `${formatMissing(assignee.firstName)} ${formatMissing(assignee.lastName)}`
    : assignee.email;
};

const formatLabel = (assignee: OrderItemAssignee): string => {
  return assignee.firstName || assignee.lastName || assignee.email
    ? `${formatMissing(assignee.firstName)} ${formatMissing(
        assignee.lastName
      )}${assignee.email ? ` (${assignee.email}` : ''})
    `
    : assignee.email;
};

export const AgileOpsAssigneesAutoComplete = ({
  className,
  value,
  valueReportId,
  onSelect,
}: Props) => {
  const { data: assigneesData } = useAgileOpsAssignees({ valueReportId });
  const assignees = useMemo(
    () => sortedAssignees(assigneesData),
    [assigneesData]
  );
  const options = useMemo(() => {
    return [
      {
        searchString:
          value === 'multi' ? 'Multiple Assignees' : 'All Assignees',
        label: value === 'multi' ? 'Multiple Assignees' : 'All Assignees',
        value: null,
      },
      ...(assignees || []).map((assignee) => {
        return {
          searchString: getSearchString(assignee),
          label: formatLabel(assignee),
          value: assignee.id,
        };
      }),
      {
        searchString: getSearchString(unassignedUser),
        label: formatLabel(unassignedUser),
        value: unassignedUser.id,
      },
    ];
  }, [assignees, value]);

  return (
    <AutoComplete
      dataHcName={dataHcName}
      className={className}
      theme={{ Input: styles.Input }}
      config={{
        selectType: 'single',
        value:
          value === 'multi' ? null : value !== null || undefined ? value : null,
        onSelect: (assigneeId) => {
          if (assigneeId === unassignedUser.id) {
            return onSelect(null);
          }
          return onSelect(assignees?.find((a) => a.id === assigneeId) || null);
        },
      }}
      options={options}
    />
  );
};
