import snakecaseKeys from 'snakecase-keys';

import { HC_ORG_ID } from '@hcs/auth';
import { OmReviewAssigneesParams, OrderItemAssignee } from '@hcs/types';

export const prepOmReviewAssigneesParamsForApi = ({
  valueReportId,
}: OmReviewAssigneesParams): OmReviewAssigneesParams => {
  return snakecaseKeys({
    ...(valueReportId ? { value_report_id: valueReportId } : undefined),
  });
};

export const sortedAssignees = (
  assignees?: OrderItemAssignee[]
): OrderItemAssignee[] | undefined => {
  if (assignees) {
    const assigneesInHcOrg = assignees.filter(
      (v) => v?.organizationId === HC_ORG_ID
    );
    const assigneesNotInHcOrg = assignees.filter(
      (v) => v?.organizationId !== HC_ORG_ID
    );
    return [...assigneesNotInHcOrg, ...assigneesInHcOrg];
  }
  return undefined;
};
