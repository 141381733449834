import { useQuery } from '@tanstack/react-query';

import { OmReviewAssigneesParams } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const QUERY_KEY_AGILE_OPS_ASSIGNEES = 'QUERY_KEY_AGILE_OPS_ASSIGNEES';
export const useAgileOpsAssignees = (params?: OmReviewAssigneesParams) => {
  return useQuery([QUERY_KEY_AGILE_OPS_ASSIGNEES, params], async () => {
    return OrderManagerReviewApi.fetchAgileOpsAssignees(params);
  });
};
