import { useEffect } from 'react';
import camelcaseKeys from 'camelcase-keys';

import { hcsConsole } from '@hcs/console';
import { useComponentDidMount } from '@hcs/hooks';
import { useComponentInternalId } from '@hcs/hooks';
import { OmAdminOrderItemEvent, OmAdminOrderItemEventsAll } from '@hcs/types';

import { ADMIN_QC_WS_BASE_URL } from '../constants/ws';

import { useOmReviewerExchangeToken } from './useOmReviewerExchangeToken';

type AdminQcOnMessageCallback = (adminQcEvent: OmAdminOrderItemEvent) => void;
interface Options {
  orderItemId?: number;
  onOpen?: VoidFunction;
  onError?: VoidFunction;
}
let adminQcWebsocketConnection: WebSocket | null = null;
// Subscribed components keep their callbacks in a global
// mapping so all components can share a single connection
const listeners: {
  onOpen: Map<string, VoidFunction>;
  onError: Map<string, VoidFunction>;
  onMessage: Map<string, AdminQcOnMessageCallback>;
} = {
  onOpen: new Map(),
  onError: new Map(),
  onMessage: new Map(),
};

// Close the websocket connection and clear the listeners
const closeWebsocketConnection = () => {
  hcsConsole.log('ADMIN-QC-Api: disconnected');
  listeners.onOpen.clear();
  listeners.onMessage.clear();
  listeners.onError.clear();
  adminQcWebsocketConnection?.close();
  adminQcWebsocketConnection = null;
};

export const useSubscribeToOmAdminQcEvents = (
  onMessage: AdminQcOnMessageCallback,
  options?: Options
) => {
  // Internal Component Id used to identify the component that is
  // subscribed to the websocket updates
  const internalId = useComponentInternalId();
  const { orderItemId, onOpen, onError } = options || {};
  // Token must be exchange token in order to open websocket
  const { data: exchangeToken } = useOmReviewerExchangeToken();
  // Create Websocket Connection and retry once
  const createWebsocketConnection = () => {
    adminQcWebsocketConnection = new WebSocket(
      `${ADMIN_QC_WS_BASE_URL}${exchangeToken?.token}`
    );
    adminQcWebsocketConnection.onopen = () => {
      hcsConsole.log('ADMIN-QC-Api: connected');
      listeners.onOpen.forEach((callback) => {
        callback();
      });
    };
    adminQcWebsocketConnection.onmessage = (event: MessageEvent<string>) => {
      const data = camelcaseKeys(JSON.parse(event.data), {
        deep: true,
      }) as OmAdminOrderItemEventsAll;
      hcsConsole.logVerbose('ADMIN-QC-API: WS |', data);

      if (data.stream !== 'debug') {
        listeners.onMessage.forEach((callback) => {
          callback(data);
        });
      }
    };
    adminQcWebsocketConnection.onerror = async (err) => {
      console.error(err);
      listeners.onError.forEach((callback) => {
        callback();
      });
    };
  };
  // Keep listeners up to date
  useEffect(() => {
    // Add listeners to the global mapping
    if (orderItemId) {
      // Filter messages
      listeners.onMessage.set(internalId, (adminQcEvent) => {
        onMessage(adminQcEvent);
      });
    } else {
      listeners.onMessage.set(internalId, onMessage);
    }
    if (onOpen) {
      listeners.onOpen.set(internalId, onOpen);
    }
    if (onError) {
      listeners.onOpen.set(internalId, onError);
    }
  }, [orderItemId, onMessage, onOpen, onError]);

  // Open and close connection
  useComponentDidMount(() => {
    // Do not attempt to connect if there isn't a exchange token
    // Create the websocket connection if it does not exist
    if (exchangeToken?.token && !adminQcWebsocketConnection) {
      createWebsocketConnection();
    }
    return () => {
      // Unsubscribe from global listeners
      listeners.onMessage.delete(internalId);
      listeners.onError.delete(internalId);
      listeners.onOpen.delete(internalId);
      // Close the connection if components are no longer subscribed
      if (!listeners.onMessage.size) {
        closeWebsocketConnection();
      }
    };
  });
};
