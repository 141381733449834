import React, { useEffect, useState } from 'react';

import { TextButton } from '@hcs/design-system';
import { Input } from '@hcs/design-system';
import { TextArea } from '@hcs/design-system';
import { InspectionPhoto } from '@hcs/types';
import { formatDateShort } from '@hcs/utils';

import { useDeleteOmAdminOrderItemInspectionImage } from '../../hooks/useDeleteOmAdminOrderItemInspectionImage';
import { useUpdateOmAdminOrderItemInspectionImage } from '../../hooks/useUpdateOmAdminOrderItemInspectionImage';

import styles from './OmAdminOrderItemPhotoCarousel.module.css';

interface Props {
  thumbnailImages: InspectionPhoto[] | null;
  setImageIndex: React.Dispatch<React.SetStateAction<number | null>>;
  currentIndex?: number;
}

const dataHcName = 'om-admin-order-item-photo-carousel-thumbnail-label';
export const ThumbnailLabel = (props: Props) => {
  const { thumbnailImages, setImageIndex, ...rest } = props;
  const [thumbnailContent, setThumbnailContent] = useState<InspectionPhoto>({
    captureTimestamp: null,
    createdAt: '',
    id: 0,
    image: '',
    inspection: 0,
    inspectionOrder: null,
    label: null,
    latitude: null,
    longitude: null,
    notes: null,
    updatedAt: null,
  });

  const [labelVal, setLabelVal] = useState<string | null>(
    thumbnailContent?.label
  );
  const [notesVal, setNotesVal] = useState<string | null>(
    thumbnailContent?.notes
  );
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    const { currentIndex } = rest;
    if (currentIndex !== undefined) {
      if (thumbnailImages) {
        setLabelVal(thumbnailImages[currentIndex]?.label as string | null);
        setNotesVal(thumbnailImages[currentIndex]?.notes as string | null);
        setThumbnailContent(thumbnailImages[currentIndex] as InspectionPhoto);
      }
    }
  }, [thumbnailImages, rest.currentIndex]);

  const updateInspectionImageMutation =
    useUpdateOmAdminOrderItemInspectionImage();

  const deleteInspectionImageMutation =
    useDeleteOmAdminOrderItemInspectionImage();

  const handleBlur = () => {
    if (
      thumbnailContent.label !== labelVal ||
      thumbnailContent.notes !== notesVal
    ) {
      updateInspectionImageMutation.mutate({
        id: thumbnailContent.id,
        label: labelVal,
        notes: notesVal,
      });
    }
  };

  useEffect(() => {
    if (rest.currentIndex !== null && rest.currentIndex !== undefined) {
      setImageIndex(rest.currentIndex);
    }
  }, [rest.currentIndex]);

  const handleDeleteImage = () => {
    if (thumbnailContent) {
      deleteInspectionImageMutation.mutate(thumbnailContent.id);
    }
    setConfirmDelete(false);
  };

  return thumbnailContent ? (
    <div className={styles.ThumbLabel} onClick={(e) => e.stopPropagation()}>
      <div className={styles.ThumbInputLabelWrapper}>
        <Input
          dataHcName="thumbnail-label"
          className={styles.ThumbInputLabel}
          value={labelVal || ''}
          onChange={setLabelVal}
          onBlur={() => handleBlur()}
        />
        {formatDateShort(thumbnailContent?.createdAt)}
      </div>
      <div className={styles.ThumbInputNotes}>
        <TextArea
          dataHcName="thumbnail-notes"
          className={styles.ThumbInputNotes}
          value={notesVal || ''}
          onChange={setNotesVal}
          onBlur={() => handleBlur()}
        />
      </div>
      <div className={styles.ThumbInputActions}>
        {confirmDelete ? (
          <span>
            Are you sure?{' '}
            <TextButton
              dataHcName={`${dataHcName}-delete-button-confirm`}
              onClick={handleDeleteImage}
            >
              Yes
            </TextButton>{' '}
            <TextButton
              dataHcName={`${dataHcName}-delete-button-cancel`}
              onClick={() => setConfirmDelete(false)}
            >
              Cancel
            </TextButton>
          </span>
        ) : (
          <TextButton
            dataHcName={`${dataHcName}-delete-button`}
            onClick={() => setConfirmDelete(true)}
          >
            Delete Photo
          </TextButton>
        )}
      </div>
    </div>
  ) : null;
};
