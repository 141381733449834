import { useQuery } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';

import { AxiosClient } from '@hcs/http-clients';
import { OmReport } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { useOmAdminOrderItem } from './useOmAdminOrderItem';

// attempt to use hook on orderItem that doesn't have a report backing would
export const QUERY_KEY_OM_ADMIN_REVIEW_ORDER_REPORT =
  'QUERY_KEY_OM_ADMIN_REVIEW_ORDER_REPORT';
export const useOmAdminOrderItemReport = (orderItemId: number) => {
  const orderItemQuery = useOmAdminOrderItem(orderItemId);

  const reportQuery = useQuery(
    [QUERY_KEY_OM_ADMIN_REVIEW_ORDER_REPORT, orderItemId],
    async () => {
      if (orderItemQuery.data?.valueReportData) {
        // the s3 url to download the report data comes from the order item response
        const response = await AxiosClient.get<OmReport>(
          orderItemQuery.data?.valueReportData
        );
        return camelcaseKeys(response.data, { deep: true });
      }
      return undefined;
    },
    {
      enabled: !orderItemQuery.isInitialLoading,
    }
  );
  const combinedQuery = combineUseQueryResult([orderItemQuery, reportQuery]);

  return {
    ...combinedQuery,
    data: combinedQuery.isInitialLoading ? undefined : reportQuery.data,
  };
};
