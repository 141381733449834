import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemAssignPayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_OM_ADMIN_REVIEW_ORDER } from './useOmAdminOrderItem';
import { QUERY_KEY_ORDER_ITEMS_ADMIN } from './useOrderItemsAdmin';

// Export hook-specific types here rather than in types file
type UseOrderItemsAssignOptions = UseMutationOptions<
  void,
  AxiosError,
  OrderItemAssignPayload,
  void
>;

export const useOrderItemsAssign = (options?: UseOrderItemsAssignOptions) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError, OrderItemAssignPayload, void>(
    async (payload) => {
      return await OrderManagerReviewApi.orderItemsAssign(payload);
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        toastOpen({
          type: 'error',
          title: error.message,
        });
      },
      onSuccess(response, variables) {
        options?.onSuccess?.(response, variables);
        queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS_ADMIN]);
        queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_REVIEW_ORDER]);
        toastOpen({
          type: 'success',
          title: 'Order assignment completed successfully',
        });
      },
    }
  );
};
