import React from 'react';
import classNames from 'classnames';

import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { OmAdminOrderItem } from '@hcs/types';

import { AgileOpsOrderItemUpdate } from './AgileOpsOrderItemUpdate';

import styles from './AgileOpsOrderItemUpdate.module.css';

interface Props {
  active: boolean;
  className?: string;
  orderItem: OmAdminOrderItem;
  onClose: VoidFunction;
}
const dataHcName = 'agile-ops-order-update-dialog';
export const AgileOpsOrderUpdateDialog = ({
  active,
  className,
  orderItem,
  onClose,
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      active={active}
      title="Reason for value change"
      theme={{
        Dialog: classNames(styles.Dialog, className),
        DialogContent: styles.DialogSection,
      }}
      onClose={onClose}
      type="small"
      noContentPadding
    >
      <section className={styles.DialogSection}>
        <AgileOpsOrderItemUpdate
          onClose={onClose}
          valueReportId={orderItem.valueReportId}
          actionButtonsPortalId={DIALOG_ACTIONS_PORTAL_ID}
        />
      </section>
    </Dialog>
  );
};
