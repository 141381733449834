import React from 'react';

import { GlobalHeader, GlobalHeaderExtendableProps } from '@hcs/auth';
import { UserMenuButtonProps } from '@hcs/design-system';
import { AppEnvironmentDisplay } from '@hcs/dev-tools';

import { PartnerToolsAppLauncher } from '../PartnerToolsAppLauncher';
import { PartnerToolsUserMenu } from '../PartnerToolsUserMenu';

export interface PartnerToolsGlobalHeaderProps
  extends GlobalHeaderExtendableProps {
  buttonGroups?: UserMenuButtonProps[][];
}
export const PartnerToolsGlobalHeader = ({
  buttonGroups,
  ...props
}: PartnerToolsGlobalHeaderProps) => {
  return (
    <>
      <AppEnvironmentDisplay />
      <GlobalHeader
        {...props}
        appLauncher={<PartnerToolsAppLauncher />}
        authenticatedActionPopovers={
          <>
            {props.authenticatedActionPopovers}
            <PartnerToolsUserMenu buttonGroups={buttonGroups} />
          </>
        }
      />
    </>
  );
};
