import React from 'react';

export const AgileOpsAllAddressesPage = React.lazy(
  () => import('./AgileOpsAllAddressesPage')
);
export const AgileOpsReviewQueuePage = React.lazy(
  () => import('./AgileOpsReviewQueuePage')
);
export const AgileOpsOrderItemPage = React.lazy(
  () => import('./AgileOpsOrderItemPage')
);
