import { OrderItemStatus } from '@hcs/types';
import { OrderItemTableColumns } from '@hcs/types';

export type OrderItemStatusMapping = Partial<{
  [key in OrderItemStatus]: boolean;
}>;

export const AGILE_OPS_REVIEW_ORDER_ITEM_STATUSES_INSPECTION: OrderItemStatusMapping =
  {
    [OrderItemStatus.InspectionReview]: true,
    [OrderItemStatus.InspectionCorrectionReview]: true,
  };

export const AGILE_OPS_REVIEW_ORDER_ITEM_STATUSES_VALUATION: OrderItemStatusMapping =
  {
    ValuationReview: true,
    QCReview: true,
  };

export const AGILE_OPS_REVIEW_ORDER_ITEM_STATUSES: OrderItemStatusMapping = {
  ...AGILE_OPS_REVIEW_ORDER_ITEM_STATUSES_INSPECTION,
  ...AGILE_OPS_REVIEW_ORDER_ITEM_STATUSES_VALUATION,
};

export const ORDER_ITEM_TABLE_COLUMNS_DEFAULT_ORDER = [
  OrderItemTableColumns.address,
  OrderItemTableColumns.orderName,
  OrderItemTableColumns.orderTypeId,
  OrderItemTableColumns.orderOrganizationId,
  OrderItemTableColumns.inspectionPartnerName,
  OrderItemTableColumns.createdAt,
  OrderItemTableColumns.hasActionableMessages,
  OrderItemTableColumns.dueDate,
  OrderItemTableColumns.status,
  OrderItemTableColumns.currentStatusDate,
  OrderItemTableColumns.assigneeName,
  OrderItemTableColumns.qcFlagsUnresolvedCount,
];
