import { Serie } from '@nivo/line';
import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { AgileSuitePdfOrderTypeIds } from '../order-manager';
import {
  InspectionData,
  InspectionPhoto,
} from '../order-manager/Inspection.types';
import { OrderItemStatus } from '../order-manager/OrderItem.types';

export enum OrderItemFilterIds {
  review = 'review',
  status = 'status',
  assignee = 'assignee',
  orderOrganizationId = 'orderOrganizationId',
  orderTypeId = 'orderTypeId',
  inspectionPartnerName = 'inspectionPartnerName',
  search = 'search',
  sortOrder = 'sortOrder',
  sortBy = 'sortBy',
  page = 'page',
  pageSize = 'pageSize',
  daysBack = 'daysBack',
  orderOrganizationSearch = 'orderOrganizationSearch',
}

export enum OrderItemTableColumns {
  address = 'address',
  assigneeName = 'assigneeName',
  createdAt = 'createdAt',
  currentStatusDate = 'currentStatusDate',
  dueDate = 'dueDate',
  hasActionableMessages = 'hasActionableMessages',
  inspectionPartnerName = 'inspectionPartnerName',
  orderName = 'orderName',
  orderOrganizationId = 'orderOrganizationId',
  orderTypeId = 'orderTypeId',
  qcFlagsUnresolvedCount = 'qcFlagsUnresolvedCount',
  status = 'status',
}

export enum ValuationHistoryItemsTableColumns {
  effective = 'effective',
  client = 'client',
  source = 'source',
  valueConclusion = 'valueConclusion',
  condition = 'condition',
  download = 'download',
}

export interface OmAdminOrderItemParams {
  [OrderItemFilterIds.review]?: boolean;
  [OrderItemFilterIds.status]?: OrderItemStatus;
  [OrderItemFilterIds.assignee]?: number;
  [OrderItemFilterIds.orderOrganizationId]?: number;
  [OrderItemFilterIds.orderTypeId]?: AgileSuitePdfOrderTypeIds[] | [];
  [OrderItemFilterIds.inspectionPartnerName]?: string;
  [OrderItemFilterIds.search]?: string;
  [OrderItemFilterIds.sortOrder]?: 'ASC' | 'DESC';
  [OrderItemFilterIds.sortBy]?: string;
  [OrderItemFilterIds.page]?: number;
  [OrderItemFilterIds.pageSize]?: number;
  [OrderItemFilterIds.daysBack]?: string;
  [OrderItemFilterIds.orderOrganizationSearch]?: {
    id: number;
    name: string;
  } | null;
}

export interface OmAdminOrderItemParamsForApi {
  review?: boolean;
  ordering?: string;
  status?: OrderItemStatus;
  assignee?: number;
  orderOrganizationId?: number;
  orderTypeId?: string;
  inspectionPartnerName?: string;
  search?: string;
}

export interface OmReviewAssigneesParams {
  valueReportId?: number | null;
}

export interface OmAdminOrderItem {
  address: string;
  assignee: OrderItemAssignee | null;
  availableDownloadables: []; // TODO: Type better
  city: string;
  createdAt: string;
  currentStatusDate: string | null;
  dueDate: string | null;
  hasActionableMessages: boolean | null;
  id: number;
  inspectionChangeLink: string | null;
  inspectionData: InspectionData | null; // TODO: Type better
  inspectionDate: string | null;
  inspectionDueDate: string | null;
  inspectionId: number | null;
  inspectionImages: InspectionPhoto[] | null;
  inspectionPartnerName: string;
  inspectionReviewInstructions: string | null;
  inspectionReviewPending: boolean | null;
  orderClientName: string;
  orderCustomerOrderId: string | null;
  orderId: number;
  orderName: string;
  orderOrganizationId: string;
  orderOrganizationName: string;
  orderTypeId: AgileSuitePdfOrderTypeIds;
  orderTypeName: string; // TODO: Type better
  qcFlags: OrderItemQcFlag[];
  qcFlagsUnresolvedCount: number;
  state: string;
  status: OrderItemStatus;
  unit: string | null;
  updatedAt: string | null;
  valueReportData: null; // TODO: Type Better
  valueReportId: number | null;
  valueReportLink: string | null;
  valueReportReviewInstructions: null; // TODO: Type Better
  valueReportReviewLevel: null; // TODO: Type Better
  valueReportReviewPending: boolean | null;
  valueReportUpdatedAt: string | null;
  viewType: null; // TODO: Type Better
  zipcode: string;
}

export interface OrderItemAssignee {
  email: string;
  firstName: string | null;
  id: number;
  lastName: string | null;
  organizationId: number;
  licenseState: string | null;
  licenseStates: string[] | null;
}

export interface OrderItemOrganizations {
  numResults: number;
  objects: {
    id: number;
    name: string;
  }[];
  page: number;
  totalPages: number;
}
export interface OrderItemAssignPayload {
  orderItemIds: number[];
  assignee: {
    id: number;
    organizationId?: number;
  } | null;
}

export interface OrderItemApprovePayload {
  orderItemIds: number[];
}

export interface OrderItemApproveResponse {
  approved: number;
  errors: [];
  failed: number;
}

export interface OrderItemRevisionPayload {
  orderItemId: number | null;
  message: string;
}
export interface OrderItemInspectionRevisionPayload {
  inspectionId: number | null;
  message: string;
}

export enum UpdateOptions {
  AboveBelowAllComps = 'above_below_all_comps',
  NoClearSupport = 'no_clear_support',
  Distance = 'distance',
  DiffPropertyType = 'diff_property_type',
  NoHighSimilarityComps = 'no_high_similarity_comps',
  MissingCharacteristics = 'missing_characteristics',
  ModifiedCharacteristics = 'modified_characteristics',
  ModifiedCondition = 'modified_condition',
  OtherReason = 'other_reason',
  ClericalUpdate = 'clerical_update',
}

export interface OrderItemUpdatePayload {
  valueReportId: number;
  comments: string;
  reasons: UpdateOptions;
}

export enum OmAdminOrderItemEventType {
  Created = 'created',
  Updated = 'updated',
  Submitted = 'submitted',
  ValueReportOrder = 'valuereportorder',
  Order = 'order',
}
export interface OmAdminOrderItemEvent {
  stream: OmAdminOrderItemEventType;
  payload: {
    action: string;
    orderId: number | number;
    data: {
      cancelled: null | string;
      completed: null | string;
      createdAt: string;
      id: number | string;
      status: string;
      test: null | string;
      updatedAt: string;
    };
  };
}

export interface OmAdminOrderItemEventDebug {
  stream: 'debug';
  payload: {
    websocketId: string;
  };
}

export type OmAdminOrderItemEventsAll =
  | OmAdminOrderItemEvent
  | OmAdminOrderItemEventDebug;

export interface OrderItemQcFlag {
  createdAt: string;
  displayMessage: string | null;
  id: number;
  inspectionId: number | null;
  rule: string | null;
  valueReportId: number | null;
}

export interface OrderItemPdfDownload {
  url: string;
}

interface OrderItemActivityMetadata {
  displayText: string | null;
  title: string;
  comment?: string;
  commentId?: number;
  isActionRequired?: boolean;
  xmlMessageBody?: string;
  xmlMessageDate?: string;
  xmlMessageFrom?: string;
  xmlMessageId?: string;
  xmlMessageSubject?: string;
}

export interface OrderItemActivity {
  id: number;
  eventType: string;
  metadata: OrderItemActivityMetadata;
  timestamp: string;
  user: OrderItemAssignee;
}

export interface OrderItemActivityCommentPayload {
  comment: string;
  createdAt: string;
  id: number;
  isActionRequired: boolean;
  isInboundXmlMessage: boolean;
  type: string;
  xmlMessageId: string;
}

export interface OrderItemActivityActionRequiredPayload {
  orderItemId: number;
  commentId: number | null;
  xmlMessageId: string;
  comment: string;
}

export interface OrderItemValuationHistory {
  addressSlug: string;
  condition: string;
  frontPhoto: {
    captureTimestamp: string | null;
    createdAt: string;
    id: number;
    image: string;
    inspection: number;
    inspectionOrder: string | null;
    label: string;
    latitude: string | null;
    longitude: string | null;
    notes: string | null;
    updatedAt: string | null;
  };
  orderId: number;
  orderItemId: number;
  orderOrganizationId: string | null;
  orderOrganizationName: string | null;
  orderTypeId: string | null;
  orderTypeName: string | null;
  reportDate: string;
  status: string | null;
  valueConclusion: number | null;
  valueReportData: string | null;
}

export interface OmValuationHistoryItem {
  reportDate: string;
  orderOrganizationName: string | null;
  orderTypeName: string | null;
  valueConclusion: number | null;
  condition: string;
  download: {
    disabled: boolean;
    addressSlug: string;
    orderItemId: number;
    orderId: number;
  };
}

export interface OmValuationHistoryChartConfig {
  chartData: Serie[];
  xAxisTickValues: (Date | undefined)[];
}

export interface OmValuationHistoryTableConfig {
  tableHeaders: string[];
  tableRows: OmValuationHistoryItem[] | [];
}

export interface OrderItemForm {
  assignee: OrderItemAssignee;
  form: string;
  message: string | null;
  status: string | null;
}

export interface OrderItemInspectionImagePayload {
  id: number;
  label: string | null;
  notes: string | null;
}

export type InspectionViewState = 'form' | 'pdf';

export type UseOmAdminOrderItemRemoveCompsOptions = UseMutationOptions<
  void,
  AxiosError,
  UseOmAdminOrderItemRemoveCompsPayload,
  void
>;

export interface UseOmAdminOrderItemRemoveCompsPayload {
  orderItemId: number;
  hcAddressIds: number[];
}

export interface OmAdminActivityLogNewMessagePayload {
  orderItemId: number;
  comment: string;
}

export type CompDetailsTypes = 'compsSold' | 'compsListing';

export interface OrderItemCompDetails {
  type: string;
  property: string;
  currentValue: number;
  currentValueColor: string;
  soldPrice: number;
  soldPriceColor: string;
}
