import { OmComp } from '@hcs/types';
import { PropertyStateFields, PropertyStateFlat } from '@hcs/types';
import { CompFields, CompFieldsArgs } from '@hcs/types';

export const pinkmanCompToFlatPropertyDetails = (
  omComp: OmComp
): {
  propertyDetails: PropertyStateFlat;
  compFields: CompFieldsArgs['comp'];
  address: {
    hcAddressId: number;
    address: string;
    city: string;
    state: string;
    zipcode: string | null;
    unit: string | null;
  };
  adjustedPrices: {
    salesPriceAdjusted: number | null;
    listPriceAdjusted: number | null;
  };
} => {
  return {
    propertyDetails: {
      [PropertyStateFields.currentValue]: omComp.currentValue,
      [PropertyStateFields.lastClosePrice]: omComp.salesPrice,
      [PropertyStateFields.currentListingPrice]: omComp.lastListPrice,
    },
    compFields: {
      compID: '_bogus_comp_id',
      propertyState: {
        hcAddressId: 0,
      },
      [CompFields.similarity]: {
        level: omComp.similarityLevel || null,
        score: omComp.similarityScore,
        levelAdjusted: omComp.similarityLevelAdjusted || null,
        scoreAdjusted: omComp.similarityScoreAdjusted,
        salesPriceAdjusted: omComp.salesPriceAdjusted,
      },
      [CompFields.distance]: omComp.distanceMiles,
    },
    address: {
      hcAddressId: omComp.addressId,
      address: omComp.streetAddress,
      city: omComp.city,
      state: omComp.state,
      zipcode: omComp.zipcode,
      unit: omComp.unit,
    },
    adjustedPrices: {
      salesPriceAdjusted: omComp.salesPriceAdjusted,
      listPriceAdjusted: omComp.listPriceAdjusted,
    },
  };
};
