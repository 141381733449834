import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ActionButtons } from '@hcs/design-system';
import { Dialog } from '@hcs/design-system';
import { UseOmAdminOrderItemRemoveCompsPayload } from '@hcs/types';

import styles from './OmAdminOrderItemRemoveCompDialog.module.css';

interface Props {
  active: boolean;
  className?: string;
  orderItemId: number;
  onClose: VoidFunction;
  removableComps: number[] | null;
  setRemovableComps: (v: number[] | null) => void;
  removeCompsMutation: UseMutationResult<
    void,
    AxiosError,
    UseOmAdminOrderItemRemoveCompsPayload,
    void
  >;
}

const dataHcName = 'om-admin-order-item-remove-comp-dialog';
export const OmAdminOrderItemRemoveCompDialog = ({
  active,
  orderItemId,
  onClose,
  removableComps,
  removeCompsMutation,
}: Props) => {
  const handleRemove = () => {
    removeCompsMutation.mutate({
      orderItemId,
      hcAddressIds: removableComps || [],
    });
  };

  return (
    <Dialog
      dataHcName={dataHcName}
      active={active}
      title="Comp Removal"
      onClose={onClose}
      type="small"
    >
      <section>
        <p>
          By removing the selected comparable(s) you acknowledge that this
          report may be incomplete if you do not select a new comp in it’s
          place.
        </p>
        <p className={styles.WarningText}>This action cannot be undone.</p>
        <ActionButtons
          dataHcName={`${dataHcName}-actions`}
          className={styles.ActionButtons}
          actions={[
            {
              dataHcName: `${dataHcName}-cancel`,
              label: 'Cancel',
              onClick: onClose,
              secondary: true,
            },
            {
              dataHcName: `${dataHcName}-update-report`,
              label: 'Update Report',
              onClick: handleRemove,
              loading: removeCompsMutation.isLoading,
            },
          ]}
        />
      </section>
    </Dialog>
  );
};
