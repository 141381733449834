import { useMemo } from 'react';
import { Serie } from '@nivo/line';

import {
  OmValuationHistoryTableConfig,
  OrderItemValuationHistory,
} from '@hcs/types';

import { useOmAdminOrderItemValuationHistory } from './useOmAdminOrderItemValuationHistory';

export const useValuationHistoryData = (orderItemId: number) => {
  const historyDataQuery = useOmAdminOrderItemValuationHistory(orderItemId);

  type ChartDataItem = { x: Date; y: number };
  const valueData: ChartDataItem[] = [];
  const chartData: Serie[] = [
    {
      id: 'Valuation History',
      data: [],
      color: '#FDB813',
    },
  ];
  const tableConfig: OmValuationHistoryTableConfig = {
    tableHeaders: [
      'Effective',
      'Client',
      'Source',
      'Value Conclusion',
      'Condition',
      'Download',
    ],
    tableRows: [],
  };
  let max: number | undefined;
  let min: number | undefined;

  const removeDuplicateValuesFromData = (
    array: ChartDataItem[],
    key: keyof ChartDataItem
  ) => {
    return array.filter(
      (obj, index, self) =>
        index === self.findIndex((el) => el[key] === obj[key])
    );
  };

  const filterHistoryDataByOrderItemId = useMemo(() => {
    return historyDataQuery.data?.filter(
      (item: OrderItemValuationHistory) => item.orderItemId !== orderItemId
    );
  }, [historyDataQuery.data]);

  useMemo(() => {
    filterHistoryDataByOrderItemId?.forEach(
      (item: OrderItemValuationHistory) => {
        if (item.valueConclusion && (!max || item.valueConclusion > max)) {
          max = item.valueConclusion;
        }
        if (item.valueConclusion && (!min || item.valueConclusion < min)) {
          min = item.valueConclusion;
        }
        if (
          item.valueConclusion &&
          item.reportDate &&
          item.status === 'Complete'
        ) {
          valueData.push({
            x: new Date(item.reportDate),
            y: item.valueConclusion,
          });
        }
      }
    );
  }, [historyDataQuery.data]);
  useMemo(() => {
    filterHistoryDataByOrderItemId?.forEach(
      (item: OrderItemValuationHistory) => {
        tableConfig.tableRows = [
          ...tableConfig.tableRows,
          {
            reportDate: item.reportDate,
            orderOrganizationName: item.orderOrganizationName,
            orderTypeName: item.orderTypeName,
            valueConclusion: item.valueConclusion,
            condition: item.condition,
            download: {
              disabled:
                !item.orderItemId ||
                !item.orderId ||
                (item.status !== 'Complete' &&
                  item.status !== 'ValuationReview' &&
                  item.status !== 'QCReview'),
              addressSlug: item.addressSlug,
              orderItemId: item.orderItemId,
              orderId: item.orderId,
            },
          },
        ];
      }
    );
  }, [historyDataQuery.data]);

  const sortedValues = valueData.sort((x, y) => {
    const prevDate = x.x;
    const nextDate = y.x;
    return new Date(prevDate).valueOf() - new Date(nextDate).valueOf();
  });

  tableConfig.tableRows.sort((x, y) => {
    const prevDate = x.reportDate;
    const nextDate = y.reportDate;
    return new Date(nextDate).valueOf() - new Date(prevDate).valueOf();
  });

  // Create X axis labels, spaced out evenly in 3 month increments
  const start = valueData?.[0]?.x;
  const end = valueData[valueData.length - 1]?.x;
  const xAxisTickValues = [start];
  if (start && end) {
    const loop = new Date(start);
    while (loop < new Date(end)) {
      const newDate = loop.setMonth(loop.getMonth() + 3);
      xAxisTickValues.push(new Date(newDate));
    }
  }
  const finalValues = removeDuplicateValuesFromData(sortedValues, 'y');

  chartData[0]?.data.push(...finalValues);

  const chartConfig = {
    chartData,
    xAxisTickValues,
  };

  return { ...historyDataQuery, data: { chartConfig, tableConfig } };
};
