import { useQuery } from '@tanstack/react-query';

import { OrderItemActivity } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const QUERY_KEY_OM_ADMIN_ACTIVITY = 'QUERY_KEY_OM_ADMIN_ACTIVITY';
export const useOmAdminOrderItemActivity = (
  orderItemId: number,
  filterType?: string
) => {
  const queryResults = useQuery(
    [QUERY_KEY_OM_ADMIN_ACTIVITY, orderItemId],
    async () => {
      return await OrderManagerReviewApi.fetchOmAdminOrderItemActivity(
        orderItemId
      );
    }
  );

  const fnmaComments: OrderItemActivity[] = [];
  const nonComments: OrderItemActivity[] = [];

  queryResults.data?.forEach((item) => {
    if (item.eventType === 'comment' && item.metadata.commentId) {
      fnmaComments.push(item);
    } else {
      nonComments.push(item);
    }
  });

  // create array of unique commentIds
  const commentIds = [
    ...new Set(fnmaComments.map((item) => item.metadata.commentId)),
  ];

  // Look up original comments by Id, removes audit log comments
  const updatedComments: OrderItemActivity[] = [];
  for (const id of commentIds) {
    // find original comment with commentId
    const originalComment = fnmaComments.find(
      (comment) => comment.metadata.commentId === id
    );

    if (originalComment) {
      updatedComments.push(originalComment);
    }
  }

  const newActivities = [...nonComments, ...updatedComments];

  const orderItemActivity = newActivities.sort(
    (x, y) => new Date(y.timestamp).valueOf() - new Date(x.timestamp).valueOf()
  );

  const data = orderItemActivity?.filter((item) => {
    if (!filterType || filterType === 'selectAll') {
      return orderItemActivity;
    } else {
      return item.metadata.title.toLowerCase() === filterType.toLowerCase();
    }
  });

  return {
    ...queryResults,
    data,
  };
};
