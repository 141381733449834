import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { useToastSlice } from '@hcs/toast';

import { useOmAdminOrderItemDisabled } from '../../hooks/useOmAdminOrderItemDisabled';
import { useOmAdminOrderItemInspectionForm } from '../../hooks/useOmAdminOrderItemInspectionForm';
import { useSubmitOmAdminOrderItemInspectionForm } from '../../hooks/useSubmitOmAdminOrderItemInspectionForm';

import '../../css/inspection-form.css';
import styles from './OmAdminOrderItemForm.module.css';

interface Props {
  inspectionId: number;
  orderItemId: number;
}
const dataHcName = 'om-admin-order-item-form';

export const OmAdminOrderItemForm = ({ inspectionId, orderItemId }: Props) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  const { data: orderItemFormInspection } =
    useOmAdminOrderItemInspectionForm(inspectionId);
  const submitOmAdminFormMutation = useSubmitOmAdminOrderItemInspectionForm();
  const { data: orderItemDisabled } = useOmAdminOrderItemDisabled(orderItemId);

  return orderItemFormInspection ? (
    <div data-hc-name={`${dataHcName}`} className={styles.Form}>
      {orderItemDisabled?.isNotAssignee || orderItemDisabled?.orderComplete ? (
        <div
          className={styles.FormDisabled}
          onClick={() =>
            toastOpen({
              type: 'info',
              title: orderItemDisabled?.isNotAssignee
                ? 'You must be the Assigned User to Edit'
                : 'Order has already been completed, unable to edit',
            })
          }
        />
      ) : null}

      <div
        className={styles.InspectionForm}
        dangerouslySetInnerHTML={{
          __html: submitOmAdminFormMutation?.data
            ? submitOmAdminFormMutation.data?.form
            : orderItemFormInspection?.form,
        }}
      />
    </div>
  ) : (
    <LoadingSpinner dataHcName={`${dataHcName}-loading`} absoluteCenter />
  );
};
