import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { OrderItemQcFlag } from '@hcs/types';

import styles from './OmAdminOrderItemFlags.module.css';

interface Props extends OrderItemQcFlag {
  showDate: boolean;
}

const dataHcName = 'qc-flag-item';
export const QcFlagItem = (props: Props) => {
  const { rule, displayMessage, createdAt } = props;

  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.QcFlagItem, {
        [styles.dateVisible]: props.showDate,
      })}
    >
      {props.showDate ? (
        <div className={styles.Date} data-hc-name={`${dataHcName}-date`}>
          {format(new Date(createdAt), 'E LLL, d')}
        </div>
      ) : null}
      <span
        className={styles.FlagRule}
        data-hc-name={`${dataHcName}-flag-rule`}
      >
        <strong>{rule}</strong>
      </span>
      <span
        className={styles.FlagMessage}
        data-hc-name={`${dataHcName}-flag-message`}
      >
        {displayMessage}
      </span>
    </div>
  );
};
