import React, { useState } from 'react';

import { CompDetailsTypes } from '@hcs/types';

import { CompsDetailsDropdown } from '../../features/OmAdminOrderItemCompsDetails/CompsDetailsDropdown';
import { TabsContentWrapper } from '../OmAdminOrderItemTabSection/TabsContentWrapper';

import { CompsDetailsChart } from './CompsDetailsChart';
import { CompsDetailsTable } from './CompsDetailsTable';

import styles from './OmAdminOrderItemCompsDetails.module.css';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-item-comps-details';
export const OmAdminOrderItemCompsDetails = ({ orderItemId }: Props) => {
  const [compDetailsType, setCompDetailsType] =
    useState<CompDetailsTypes>('compsSold');
  return (
    <TabsContentWrapper dataHcName={dataHcName}>
      <CompsDetailsDropdown
        compDetailsType={compDetailsType}
        setCompDetailsType={setCompDetailsType}
      />
      <div className={styles.CompsDetailsChartWrapper}>
        <CompsDetailsChart
          orderItemId={orderItemId}
          compDetailsType={compDetailsType}
        />
      </div>
      <CompsDetailsTable
        orderItemId={orderItemId}
        compDetailsType={compDetailsType}
      />
    </TabsContentWrapper>
  );
};
