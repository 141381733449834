import React, { ReactNode } from 'react';

import { useActiveState } from '@hcs/hooks';

import {
  OmAdminOrderItemActivityLogNewMessageDialog,
  OmAdminOrderItemActivityLogNewMessageDialogProps,
} from './OmAdminOrderItemActivityLogNewMessageDialog';

import styles from './OmAdminOrderItemActivityLogNewMessage.module.css';

interface Props
  extends Omit<
    OmAdminOrderItemActivityLogNewMessageDialogProps,
    'dialogProps'
  > {
  button: ReactNode;
}

const dataHcName = 'om-admin-order-item-activity-log-new-message-launcher';
export const OmAdminOrderItemActivityLogNewMessageLauncher = ({
  button,
  activityLogNewMessageProps,
}: Props) => {
  const { active, handleOpen, handleClose } = useActiveState();
  return (
    <>
      <span
        data-hc-name={`${dataHcName}-button`}
        onClick={handleOpen}
        className={styles.NewMessageButton}
      >
        {button}
      </span>
      <OmAdminOrderItemActivityLogNewMessageDialog
        dialogProps={{
          active,
          onClose: handleClose,
        }}
        activityLogNewMessageProps={activityLogNewMessageProps}
      />
    </>
  );
};
