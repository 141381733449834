import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';
import { OmAdminOrderItem, OmAdminOrderItemEvent } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_OM_ADMIN_PDF_DOWNLOAD } from './useOmAdminOrderItemPdfDownload';
import { QUERY_KEY_OM_ADMIN_REVIEW_ORDER_REPORT } from './useOmAdminOrderItemReport';
import { useSubscribeToOmAdminQcEvents } from './useSubscribeToOmAdminQcEvents';

export const QUERY_KEY_OM_ADMIN_REVIEW_ORDER =
  'QUERY_KEY_OM_ADMIN_REVIEW_ORDER';
export const useOmAdminOrderItem = (orderItemId: number) => {
  const queryClient = useQueryClient();
  const orderItemQuery = useQuery(
    [QUERY_KEY_OM_ADMIN_REVIEW_ORDER, orderItemId],
    async () => {
      if (orderItemId) {
        return await OrderManagerReviewApi.fetchOmAdminOrderItem(orderItemId);
      } else {
        throw new Error('[Order Item] No Order ID provided');
      }
    }
  );
  const {
    actions: { toastOpen },
  } = useToastSlice();

  const omAdminOrderItemEventCallback = useCallback(
    (omAdminOrderItemEvent: OmAdminOrderItemEvent) => {
      if (omAdminOrderItemEvent.payload.data) {
        const { id } = omAdminOrderItemEvent.payload.data;

        if (orderItemQuery.data?.valueReportId === id) {
          queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_PDF_DOWNLOAD]);
          queryClient.invalidateQueries([
            QUERY_KEY_OM_ADMIN_REVIEW_ORDER_REPORT,
          ]);
          toastOpen({
            type: 'success',
            title: 'Report update completed successfully.',
          });
        }

        if (orderItemQuery.data?.orderId === id) {
          queryClient.setQueryData<OmAdminOrderItem>(
            [QUERY_KEY_OM_ADMIN_REVIEW_ORDER],
            orderItemQuery.data
          );
          queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_REVIEW_ORDER]);
        }
      }
    },
    [orderItemQuery, queryClient]
  );
  useSubscribeToOmAdminQcEvents(omAdminOrderItemEventCallback, { orderItemId });
  return orderItemQuery;
};
