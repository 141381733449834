import React, { ReactNode } from 'react';

import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { useOmAdminOrderItemInspectionView } from '../../hooks/useOmAdminOrderItemInspectionView';
import {
  OmAdminOrderItemDetails,
  OmAdminOrderItemDetailsProps,
} from '../OmAdminOrderItemDetails';
import { OmAdminOrderItemInspection } from '../OmAdminOrderItemInspection';

import { OmAdminOrderItemSidebar } from './OmAdminOrderItemSidebar';

import styles from './OmAdminOrderItem.module.css';

interface Props {
  orderItemId: number;
  backButton?: ReactNode;
  onRevisionSuccess?: VoidFunction;
  approveOrderItemProps?: OmAdminOrderItemDetailsProps['approveOrderItemProps'];
}

const dataHcName = 'om-admin-order-item';

export const OmAdminOrderItem = ({
  orderItemId,
  backButton,
  onRevisionSuccess,
  approveOrderItemProps,
}: Props) => {
  const { data: orderItem } = useOmAdminOrderItem(orderItemId);
  const { status } = orderItem || {};
  const { defaultView, handleToggleView } =
    useOmAdminOrderItemInspectionView(status);

  return orderItemId ? (
    <div data-hc-name={dataHcName} className={styles.OmAdminOrderItem}>
      <div className={styles.MainWrapper}>
        {backButton}
        <OmAdminOrderItemDetails
          orderItemId={orderItemId}
          defaultView={defaultView}
          handleToggleView={handleToggleView}
          onRevisionSuccess={onRevisionSuccess}
          approveOrderItemProps={approveOrderItemProps}
        />
        <div
          data-hc-name={`${dataHcName}-inspection`}
          className={styles.Inspection}
        >
          <OmAdminOrderItemInspection
            orderItemId={orderItemId}
            defaultView={defaultView}
          />
        </div>
      </div>
      <div className={styles.Sidebar}>
        <OmAdminOrderItemSidebar orderItemId={orderItemId} />
      </div>
    </div>
  ) : null;
};
