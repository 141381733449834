import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemUpdatePayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';
// Export hook-specific types here rather than in types file
type UseOrderItemUpdateOptions = UseMutationOptions<
  void,
  AxiosError,
  OrderItemUpdatePayload,
  void
>;

export const useRegenerateOrderItemValueReport = (
  options?: UseOrderItemUpdateOptions
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError, OrderItemUpdatePayload, void>(
    async (payload) => {
      return await OrderManagerReviewApi.regenerateOrderItemValueReport(
        payload
      );
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        toastOpen({
          type: 'error',
          title: error.message,
        });
      },
      onSuccess(response, variables) {
        options?.onSuccess?.(response, variables);
        toastOpen({
          type: 'loading-success',
          title: 'Regenerating PDF',
          duration: 10000000,
        });
      },
    }
  );
};
