import React from 'react';

import { Logout } from '@hcs/auth';
import { UnauthenticatedPage } from '@hcs/auth';
import { LayoutContent, LayoutGlobalHeaderOutlet } from '@hcs/design-system';

import { PartnerToolsPageRedirect } from '../../../auth-redirects/PartnerToolsPageRedirect';
import { PartnerToolsGlobalHeader } from '../../../navigation/PartnerToolsGlobalHeader';
import {
  ForgotPasswordPage,
  LoginPage,
  PartnerToolsLandingPage,
  ResetPasswordPage,
} from '../pages';
import { VIEW_PATHS_ACCESS_PARTNER_TOOLS } from '..';

export const routes = () => [
  {
    element: <LayoutGlobalHeaderOutlet header={<PartnerToolsGlobalHeader />} />,
    children: [
      {
        path: VIEW_PATHS_ACCESS_PARTNER_TOOLS.LOGIN,
        element: (
          <UnauthenticatedPage>
            <LoginPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_PARTNER_TOOLS.LOGOUT,
        element: (
          <PartnerToolsPageRedirect noRedirectQuery>
            <Logout />
          </PartnerToolsPageRedirect>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_PARTNER_TOOLS.LANDING,
        element: (
          <PartnerToolsPageRedirect noRedirectQuery>
            <LayoutContent>
              <PartnerToolsLandingPage />
            </LayoutContent>
          </PartnerToolsPageRedirect>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_PARTNER_TOOLS.FORGOT_PASSWORD,
        element: (
          <UnauthenticatedPage>
            <ForgotPasswordPage />
          </UnauthenticatedPage>
        ),
      },
      {
        path: VIEW_PATHS_ACCESS_PARTNER_TOOLS.RESET_PASSWORD,
        element: (
          <UnauthenticatedPage>
            <ResetPasswordPage />
          </UnauthenticatedPage>
        ),
      },
    ],
  },
];
