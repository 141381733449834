import { useMemo } from 'react';

import { PhotoProp } from '@hcs/types';

import { useOmAdminOrderItem } from './useOmAdminOrderItem';

export const QUERY_KEY_ADMIN_INSPECTION_IMAGES =
  'QUERY_KEY_ADMIN_INSPECTION_IMAGES';
export const useOmAdminOrderItemInspectionImages = (orderItemId: number) => {
  const orderItemQuery = useOmAdminOrderItem(orderItemId);

  // Set the thumbnail images
  const thumbnailImages = useMemo(
    () => orderItemQuery.data?.inspectionImages?.map((image) => image),
    [orderItemQuery.data]
  );

  // Set thumbnail urls
  const thumbnailUrls = useMemo(
    () => thumbnailImages?.map((image) => ({ url: image.image })),
    [thumbnailImages]
  );

  // Set FullCarousel Images
  const carouselImages: PhotoProp[] | undefined = useMemo(
    () =>
      orderItemQuery.data?.inspectionImages?.map((image) => {
        return {
          photo: {
            url: image.image,
          },
          metadata: image.label,
        };
      }),
    [orderItemQuery.data]
  );

  return {
    ...orderItemQuery,
    data: orderItemQuery.isInitialLoading
      ? undefined
      : { thumbnailImages, thumbnailUrls, carouselImages },
  };
};
