import React from 'react';

import styles from './OmAdminOrderItemTabSection.module.css';

interface Props {
  displayText: string;
  showIndicator?: boolean;
}

const dataHcName = 'om-admin-order-item-tab-label';
export const TabsLabel = ({ displayText, showIndicator }: Props) => {
  return (
    <label data-hc-name={dataHcName}>
      {showIndicator && <span className={styles.AlertIndicator}></span>}
      {displayText}
    </label>
  );
};
