import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './OmAdminOrderItemTabSection.module.css';

interface Props {
  dataHcName: string;
  className?: string;
  children: ReactNode;
}
export const TabsContentWrapper = ({
  dataHcName,
  className,
  children,
}: Props) => {
  return (
    <div
      data-hc-name={`tabs-content-wrapper-${dataHcName}`}
      className={classnames(styles.TabsContentWrapper, className)}
    >
      {children}
    </div>
  );
};
