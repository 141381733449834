import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemApprovePayload, OrderItemApproveResponse } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_ORDER_ITEMS_ADMIN } from './useOrderItemsAdmin';

// Export hook-specific types here rather than in types file
type UseOrderItemsApproveOptions = UseMutationOptions<
  OrderItemApproveResponse,
  AxiosError,
  OrderItemApprovePayload,
  void
>;

export const useOrderItemsApprove = (options?: UseOrderItemsApproveOptions) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    OrderItemApproveResponse,
    AxiosError,
    OrderItemApprovePayload,
    void
  >(
    async (payload) => {
      return await OrderManagerReviewApi.orderItemsApprove(payload);
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        toastOpen({
          title: 'Order Item Approve Error',
          type: 'error',
        });
      },
      onSuccess(response, variables) {
        options?.onSuccess?.(response, variables);
        queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS_ADMIN]);
        toastOpen({
          title: 'Order Items Approve Success',
          type: 'success',
        });
      },
    }
  );
};
