import React, { useState } from 'react';

import { TextArea } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';

import { useCreateOmAdminOrderItemActivityLogNewMessage } from '../../hooks/useCreateOmAdminOrderItemActivityLogNewMessage';

import styles from './OmAdminOrderItemActivityLogNewMessage.module.css';

type CallbackProp = (...args: unknown[]) => void;

export interface ActivityLogNewMessageProps {
  orderItemId: number;
  actionButtonsPortalId?: string;
  onSubmit?: CallbackProp;
  onSuccess?: CallbackProp;
  onCancel?: VoidFunction;
}

const dataHcName = 'om-admin-order-item-activity-log-new-message';
export const OmAdminOrderItemActivityLogNewMessage = ({
  orderItemId,
  actionButtonsPortalId,
  onCancel,
  onSuccess,
  onSubmit,
}: ActivityLogNewMessageProps) => {
  const [message, setMessage] = useState<string>('');

  const createActivityLogNewMessageMutation =
    useCreateOmAdminOrderItemActivityLogNewMessage({
      onSuccess: () => {
        setMessage('');
        onSuccess?.();
      },
    });

  const handleSend = () => {
    createActivityLogNewMessageMutation.mutate({
      orderItemId,
      comment: message,
    });
    onSubmit?.();
  };

  const handleCancel = () => {
    setMessage('');
    onCancel?.();
  };
  return (
    <>
      <span className={styles.SubTitle}>
        This message is for internal use only
      </span>
      <TextArea
        dataHcName={`${dataHcName}-textarea`}
        className={styles.Textarea}
        value={message}
        onChange={setMessage}
        placeholder="Thanks, we should have this turned around shortly."
      />
      <ActionButtons
        portalIdRender={actionButtonsPortalId}
        dataHcName={`${dataHcName}-actions`}
        actions={[
          {
            label: 'Cancel',
            dataHcName: `${dataHcName}-cancel-button`,
            secondary: true,
            onClick: handleCancel,
          },
          {
            label: 'Send',
            dataHcName: `${dataHcName}-send-button`,
            onClick: handleSend,
            loading: createActivityLogNewMessageMutation.isLoading,
          },
        ]}
      />
    </>
  );
};
