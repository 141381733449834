import React from 'react';
import classNames from 'classnames';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { CommentIcon } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { NULL_VALUE } from '@hcs/utils';

import { DisplayProps } from '.';

import styles from '../OmAdminOrderItemsTable.module.css';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  return (
    <div data-hc-name={`${dataHcName}-messages`}>
      {orderItem?.hasActionableMessages !== null ? (
        <CommentIcon
          dataHcName={`${dataHcName}-messages-icon`}
          className={classNames({
            [styles.messagesIconDisabled]: !orderItem?.hasActionableMessages,
          })}
        />
      ) : (
        NULL_VALUE
      )}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Messages</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export type OmAdminOrderItemTableCellProps = TableCellProps & OmAdminOrderItem;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_MESSAGES_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Messages',
};
