import { isSameDay } from 'date-fns';
import snakecaseKeys from 'snakecase-keys';

import {
  OmAdminOrderItemParams,
  OmAdminOrderItemParamsForApi,
} from '@hcs/types';
import { camelCaseToSnakeCase } from '@hcs/utils';

export const prepOmAdminOrderItemParamsForApi = ({
  sortBy,
  sortOrder,
  orderOrganizationSearch,
  ...params
}: OmAdminOrderItemParams): OmAdminOrderItemParamsForApi => {
  return snakecaseKeys({
    ...params,
    ...(params.review === true
      ? { review: 'True' }
      : params.review === false
      ? { review: 'False' }
      : {}),
    ...(params.orderTypeId?.length && params.orderTypeId !== undefined
      ? { order_type_id: params.orderTypeId.toString() }
      : undefined),
    ...(params.daysBack?.length && params.daysBack !== undefined
      ? { days_back: params.daysBack }
      : undefined),
    orderOrganizationId: orderOrganizationSearch
      ? orderOrganizationSearch.id
      : undefined,
    ordering: sortBy
      ? `${sortOrder === 'DESC' ? '-' : ''}${camelCaseToSnakeCase(sortBy)}`
      : undefined,
  });
};

export const showDate = (currentDate: string, prevDate: string | undefined) => {
  if (!prevDate) {
    return true;
  }
  return !isSameDay(new Date(currentDate), new Date(prevDate));
};
