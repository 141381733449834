import React, { MouseEvent, useState } from 'react';

import { ThumbnailCarousel } from '@hcs/design-system';
import { PhotoViewer } from '@hcs/design-system';

import { useOmAdminOrderItemInspectionImages } from '../../hooks/useOmAdminOrderItemInspectionImages';

import { ThumbnailLabel } from './ThumbnailLabel';

import styles from './OmAdminOrderItemPhotoCarousel.module.css';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-item-photo-carousel';
export const OmAdminOrderItemPhotoCarousel = ({ orderItemId }: Props) => {
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);

  const { data: images } = useOmAdminOrderItemInspectionImages(orderItemId);
  const { thumbnailImages, thumbnailUrls, carouselImages } = images || {};

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setFullscreenIndex(imageIndex);
  };

  return carouselImages?.length &&
    thumbnailImages?.length &&
    thumbnailUrls?.length ? (
    <>
      <PhotoViewer
        dataHcName={`${dataHcName}-photo-viewer`}
        initialIndex={fullscreenIndex}
        photos={carouselImages}
        active={fullscreenIndex !== null}
        onClose={() => setFullscreenIndex(null)}
      />
      <div className={styles.ThumbnailWrapper}>
        <ThumbnailCarousel
          dataHcName={dataHcName}
          photos={thumbnailUrls}
          className={styles.ThumbnailCarousel}
          onClick={handleClick}
        >
          <ThumbnailLabel
            thumbnailImages={thumbnailImages}
            setImageIndex={setImageIndex}
          />
        </ThumbnailCarousel>
      </div>
    </>
  ) : (
    <div className={styles.NoPhoto}>No Photos Provided</div>
  );
};
