import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

import { useIsLoggedIn } from '@hcs/auth';
import { authDevToolsSlice } from '@hcs/auth';
import { SentryUser } from '@hcs/auth';
import { CerberusDevTool } from '@hcs/cerberus';
import { marketStateSearchSlice } from '@hcs/cerberus-stats';
import { HcsConsoleDevTool } from '@hcs/console';
import { dataExplorerSlice } from '@hcs/data-explorer';
import { SkeletonPage } from '@hcs/design-system';
import { DesignSystemStyles } from '@hcs/design-system';
import { LayoutContent, LayoutGlobalHeaderChildren } from '@hcs/design-system';
import { ExperienceFlagsDevTool, HcsDevTools } from '@hcs/dev-tools';
import {
  ENGAGEMENT_TRACKING_REDUCER_KEY,
  EngagementTracking,
  EngagementTrackingDevTool,
  engagementTrackingReducer,
} from '@hcs/engagement-tracking';
import { experienceFlagsSlice } from '@hcs/experience-flags';
import { HelpChatProvider } from '@hcs/help-chat';
import { queryClient } from '@hcs/http-clients';
import { keywordSearchSlice } from '@hcs/keyword-search';
import { hcMapSlice } from '@hcs/maps';
import { IntercomUser } from '@hcs/organization';
import { addendumSlice } from '@hcs/pdf/pdf-service';
import {
  PORTFOLIO_REDUCER_KEY,
  portfolioAssetsSlice,
  portfolioNotificationsSlice,
  portfolioReducer,
} from '@hcs/portfolio';
import { REPORT_API_REDUCER_KEY, reportApiReducer } from '@hcs/report-api';
import { RouterErrorBoundary } from '@hcs/routing';
import { SearchByMlsNumberProvider } from '@hcs/search-by-mls-number';
import { toastSlice } from '@hcs/toast';
import { Toast } from '@hcs/toast';
import { createHcReduxStore } from '@hcs/utils';
import { addErrorElementToRoutes } from '@hcs/utils';
import { initSentry, ROOT_QUERY_SELECTOR } from '@hcs/webapps';

import { VIEW_PATHS_ACCESS_PARTNER_TOOLS } from './apps/access';
import { routes as accessRoutes } from './apps/access/routes';
import { agileOpsRoutes } from './apps/agile-ops/routes';
import { routes as ordersRoutes } from './apps/orders/routes';
import { routes as pexpRoutes } from './apps/pexp/routes';
import { PartnerToolsGlobalHeader } from './navigation/PartnerToolsGlobalHeader';
import { PARTNER_TOOLS_APPS } from './apps';

window.BUNDLE_APP_CONFIGS = PARTNER_TOOLS_APPS;

initSentry();

const Landing = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  return isLoggedIn ? (
    <Navigate to={VIEW_PATHS_ACCESS_PARTNER_TOOLS.LANDING} replace />
  ) : (
    <Navigate to={VIEW_PATHS_ACCESS_PARTNER_TOOLS.LOGIN} replace />
  );
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const partnerToolsRouter = sentryCreateBrowserRouter(
  addErrorElementToRoutes(
    <LayoutGlobalHeaderChildren header={<PartnerToolsGlobalHeader />}>
      <LayoutContent>
        <RouterErrorBoundary />
      </LayoutContent>
    </LayoutGlobalHeaderChildren>,
    [
      {
        path: '/',
        element: <Landing />,
      },
      ...accessRoutes(),
      ...pexpRoutes(),
      ...ordersRoutes(),
      ...agileOpsRoutes(),
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ]
  )
);

const reduxStore = createHcReduxStore(
  [
    toastSlice,
    authDevToolsSlice,
    dataExplorerSlice,
    keywordSearchSlice,
    hcMapSlice,
    experienceFlagsSlice,
    portfolioAssetsSlice,
    portfolioNotificationsSlice,
    marketStateSearchSlice,
    addendumSlice,
  ],
  {
    [REPORT_API_REDUCER_KEY]: reportApiReducer,
    [PORTFOLIO_REDUCER_KEY]: portfolioReducer,
    [ENGAGEMENT_TRACKING_REDUCER_KEY]: engagementTrackingReducer,
  }
);

const root = ReactDOM.createRoot(
  document.querySelector(ROOT_QUERY_SELECTOR) as Element
);
root.render(
  <StrictMode>
    <SkeletonPage>
      <Provider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <SearchByMlsNumberProvider>
            <HelpChatProvider>
              <RouterProvider router={partnerToolsRouter} />
              <DesignSystemStyles />
              <HcsDevTools />
              <EngagementTracking />
              <Toast />
              <HcsConsoleDevTool />
              <EngagementTrackingDevTool />
              <ExperienceFlagsDevTool />
              <CerberusDevTool />
              <IntercomUser />
              <SentryUser />
            </HelpChatProvider>
          </SearchByMlsNumberProvider>
        </QueryClientProvider>
      </Provider>
    </SkeletonPage>
  </StrictMode>
);
