import React from 'react';

import { TableCell } from '@hcs/design-system';
import { TableCellProps } from '@hcs/types';
import { OmValuationHistoryItem } from '@hcs/types';

import { ValuationHistoryItem } from '.';

const dataHcName = 'om-admin-order-item-valuation-history-table-cell';

const Display = ({ valuationHistoryItem }: ValuationHistoryItem) => {
  return <div data-hc-name={dataHcName}>{valuationHistoryItem?.condition}</div>;
};

const ContentCell = ({
  valuationHistoryItem,
  ...tableCellProps
}: {
  valuationHistoryItem: OmValuationHistoryItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display valuationHistoryItem={valuationHistoryItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const VALUATION_HISTORY_ITEM_CONDITION_CONFIG = {
  ContentCell,
  Display,
  label: 'Condition',
};
