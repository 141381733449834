import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';

import { useOmAdminOrderItemPdfDownload } from '../../hooks/useOmAdminOrderItemPdfDownload';

import styles from './OmAdminOrderItemPdf.module.css';

interface Props {
  dataHcName: string;
  orderItemId: number;
  orderId: number;
}

const dataHcName = 'om-admin-order-item-pdf';

export const OmAdminOrderItemPdf = ({ orderItemId, orderId }: Props) => {
  const { data, isFetching, isSuccess, isError } =
    useOmAdminOrderItemPdfDownload(orderItemId, orderId);
  return (
    <>
      {isFetching && (
        <LoadingSpinner dataHcName={`${dataHcName}-spinner`} absoluteCenter />
      )}
      {isSuccess && (
        <div data-hc-name={`${dataHcName}`} className={styles.PdfViewer}>
          <iframe title="order-pdf" src={`${data?.url}#zoom=FitH`} />
        </div>
      )}
      {isError && (
        <div data-hc-name={`${dataHcName}-error`} className={styles.PdfError}>
          Error Fetching PDF
        </div>
      )}
    </>
  );
};
