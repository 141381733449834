import React from 'react';

import { Avatar } from '@hcs/design-system';
import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { formatMissing, NULL_VALUE } from '@hcs/utils';

import { DisplayProps, OmAdminOrderItemsTableCellConfig } from '.';

import styles from '../OmAdminOrderItemsTable.module.css';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  return orderItem?.assignee ? (
    <div className={styles.Assignee} data-hc-name={`${dataHcName}-assignee`}>
      <Avatar
        dataHcName={`${dataHcName}-assignee-avatar`}
        text={`${formatMissing(
          orderItem.assignee?.firstName?.slice(0, 1)
        )}${formatMissing(orderItem.assignee?.lastName?.slice(0, 1))}`}
        size="xs"
      />
      <span data-hc-name={`${dataHcName}-assignee-name`}>
        {`${formatMissing(orderItem.assignee?.firstName)} ${formatMissing(
          orderItem.assignee?.lastName
        )}`}
      </span>
    </div>
  ) : (
    <span>{NULL_VALUE}</span>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Assignee</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_ASSIGNEE_CONFIG: OmAdminOrderItemsTableCellConfig =
  {
    HeaderCell,
    ContentCell,
    Display,
    label: 'Assignee',
  };
