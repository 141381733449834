import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OrderItemActivityActionRequiredPayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export interface OmAdminActivityCommentResponse {
  commentId: string;
  xmlMessageId: string;
  isActionRequired: boolean;
}

export const useUpdateOrderItemCommentActionRequired = () => {
  return useMutation<
    OmAdminActivityCommentResponse,
    AxiosError,
    OrderItemActivityActionRequiredPayload,
    void
  >(async (OrderItemActivityActionRequiredPayload) => {
    return await OrderManagerReviewApi.updateOmAdminOrderItemMessageActionRequired(
      OrderItemActivityActionRequiredPayload
    );
  });
};
