import { useQuery } from '@tanstack/react-query';

import { OmAdminTokenResponse } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { useOmReviewerToken } from './useOmReviewerToken';

export const QUERY_KEY_OM_REVIEWER_EXCHANGE_TOKEN =
  'QUERY_KEY_OM_REVIEWER_EXCHANGE_TOKEN';
export const useOmReviewerExchangeToken = () => {
  const { data: token } = useOmReviewerToken();
  return useQuery<OmAdminTokenResponse | null>(
    [QUERY_KEY_OM_REVIEWER_EXCHANGE_TOKEN],
    async () => {
      return await OrderManagerReviewApi.fetchReviewerExchangeToken(
        token?.token
      );
    }
  );
};
