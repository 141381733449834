import React from 'react';

import { ActionButtons } from '@hcs/design-system';
import { OmAdminOrderItem } from '@hcs/types';
import { formatNumber } from '@hcs/utils';

import { useOrderItemsApprove } from '../../hooks/useOrderItemsApprove';

type CallbackProp = (orderItems: OmAdminOrderItem[]) => void;
export interface ApproveOrderItemProps {
  orderItems: OmAdminOrderItem[];
  className?: string;
  actionButtonsPortalId?: string;
  onSubmit?: CallbackProp;
  onSuccess?: CallbackProp;
  onCancel?: VoidFunction;
}
const dataHcName = 'agile-ops-order-items-approve';
export const AgileOpsOrderItemsApprove = ({
  orderItems,
  className,
  actionButtonsPortalId,
  onCancel,
  onSubmit,
  onSuccess,
}: ApproveOrderItemProps) => {
  const approveOrderItemsMutation = useOrderItemsApprove({
    onSuccess: () => {
      onSuccess?.(orderItems);
    },
  });
  const handleSubmit = () => {
    if (orderItems.length) {
      approveOrderItemsMutation.mutate({
        orderItemIds: orderItems.map((o) => o.id),
      });
      onSubmit?.(orderItems);
    }
  };
  return (
    <>
      <p data-hc-name={dataHcName} className={className}>
        Are you sure you want to approve{' '}
        <span data-hc-name={`${dataHcName}-count`}>
          {formatNumber(orderItems.length)}
        </span>{' '}
        report{`${orderItems.length === 1 ? '' : 's'}`}?
      </p>
      <ActionButtons
        portalIdRender={actionButtonsPortalId}
        dataHcName={`${dataHcName}-actions`}
        actions={[
          {
            dataHcName: `${dataHcName}-cancel`,
            label: 'No',
            onClick: onCancel,
            secondary: true,
          },
          {
            dataHcName: `${dataHcName}-submit`,
            label: 'Yes',
            disabled: approveOrderItemsMutation.isLoading,
            loading: approveOrderItemsMutation.isLoading,
            onClick: handleSubmit,
          },
        ]}
      />
    </>
  );
};
