import { OrderItemFilterIds } from '@hcs/types';

const searchParamPrefix = 'omAdminOrderItemsTable-';

export const SearchParamsOmAdminOrderItemsTable = {
  // Action Related
  SelectedItems: `${searchParamPrefix}selectedItems`,
  // Pagination Related
  Page: `${searchParamPrefix}page`,
  PageSize: `${searchParamPrefix}pageSize`,
  // Sort
  SortBy: `${searchParamPrefix}sortBy`,
  SortOrder: `${searchParamPrefix}sortOrder`,
  // Filters
  Review: `${searchParamPrefix}review`,
  Status: `${searchParamPrefix}status`,
  Assignee: `${searchParamPrefix}assignee`,
  OrderOrganizationId: `${searchParamPrefix}orderOrganizationId`,
  OrderTypeId: `${searchParamPrefix}orderTypeId`,
  InspectionPartnerName: `${searchParamPrefix}inspectionPartnerName`,
  Search: `${searchParamPrefix}search`,
  DaysBack: `${searchParamPrefix}daysBack`,
  OrderOrganizationSearch: `${searchParamPrefix}orderOrganizationSearch`,
};

export const ORDER_ITEM_FILTERS_TO_SEARCH_PARAMS: {
  [key in OrderItemFilterIds]: string;
} = {
  [OrderItemFilterIds.review]: SearchParamsOmAdminOrderItemsTable.Review,
  [OrderItemFilterIds.status]: SearchParamsOmAdminOrderItemsTable.Status,
  [OrderItemFilterIds.assignee]: SearchParamsOmAdminOrderItemsTable.Assignee,
  [OrderItemFilterIds.orderOrganizationId]:
    SearchParamsOmAdminOrderItemsTable.OrderOrganizationId,
  [OrderItemFilterIds.orderTypeId]:
    SearchParamsOmAdminOrderItemsTable.OrderTypeId,
  [OrderItemFilterIds.inspectionPartnerName]:
    SearchParamsOmAdminOrderItemsTable.InspectionPartnerName,
  [OrderItemFilterIds.search]: SearchParamsOmAdminOrderItemsTable.Search,
  [OrderItemFilterIds.sortOrder]: SearchParamsOmAdminOrderItemsTable.SortOrder,
  [OrderItemFilterIds.sortBy]: SearchParamsOmAdminOrderItemsTable.SortBy,
  [OrderItemFilterIds.page]: SearchParamsOmAdminOrderItemsTable.Page,
  [OrderItemFilterIds.pageSize]: SearchParamsOmAdminOrderItemsTable.PageSize,
  [OrderItemFilterIds.daysBack]: SearchParamsOmAdminOrderItemsTable.DaysBack,
  [OrderItemFilterIds.orderOrganizationSearch]:
    SearchParamsOmAdminOrderItemsTable.OrderOrganizationSearch,
};
