import React, { ReactNode, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { PrivateApplicationPage, PrivatePage, useAccount } from '@hcs/auth';
import { Roles } from '@hcs/types';
import { SOLUTIONS_URL } from '@hcs/urls';
import { createRedirectQuerystring } from '@hcs/utils';
import { URL_PARAM_NO_REDIRECT } from '@hcs/webapps';

import { VIEW_PATHS_ACCESS_PARTNER_TOOLS } from '../../apps/access';

// Internal component for checking that the user has the right roles to view partner tools
// When this mounts, the user is already confirmed to be logged in
const HasPartnerRoles = ({ children }: { children: ReactNode }) => {
  const { data: account } = useAccount();
  const hasRequiredRole = !!account?.user.roles.find(
    (role) => role.name === Roles.Broker || role.name === Roles.AgileOps
  );
  useEffect(() => {
    if (!hasRequiredRole) {
      // SECURITY: This is a safe url. Only a variable to account for environment
      // eslint-disable-next-line scanjs-rules/assign_to_href, xss/no-location-href-assign
      window.location.href = SOLUTIONS_URL;
    }
  }, [hasRequiredRole]);

  if (hasRequiredRole) {
    return children;
  }
  return null;
};
// Creates a page component that is accessible to anyone with a valid token
// Attempts to refresh accessToken if it is invalid and will redirect to login
// If token is invalid
export const PartnerToolsPageRedirect = ({
  children,
  noRedirectQuery,
}: {
  children: ReactNode;
  noRedirectQuery?: boolean;
}) => {
  const redirectQuery = noRedirectQuery ? '' : createRedirectQuerystring();
  return (
    <PrivatePage
      unauthenticatedRender={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_PARTNER_TOOLS.LOGIN}${redirectQuery}`}
          replace
        />
      }
    >
      <HasPartnerRoles>{children}</HasPartnerRoles>
    </PrivatePage>
  );
};

// Creates a page component that is accessible to anyone with access to the application
export const PartnerToolsApplicationPageRedirect = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const redirect =
    searchParams.get(URL_PARAM_NO_REDIRECT) === 'true'
      ? ''
      : createRedirectQuerystring();
  return (
    <PrivateApplicationPage
      unauthorizedRender={
        <Navigate to={VIEW_PATHS_ACCESS_PARTNER_TOOLS.LANDING} replace />
      }
      unauthenticatedRender={
        <Navigate
          to={`${VIEW_PATHS_ACCESS_PARTNER_TOOLS.LOGIN}${redirect}`}
          replace
        />
      }
    >
      <HasPartnerRoles>{children}</HasPartnerRoles>
    </PrivateApplicationPage>
  );
};
