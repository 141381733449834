import React from 'react';

import {
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
  DialogProps,
} from '@hcs/design-system';

import {
  AgileOpsOrderItemsAssign,
  AssignUserProps,
} from './AgileOpsOrderItemsAssign';

import styles from './AgileOpsOrderItemsAssign.module.css';

export interface AgileOpsOrderItemsAssignDialogProps {
  dialogProps: Omit<DialogProps, 'dataHcName' | 'children'>;
  assignUserProps: AssignUserProps;
}
const dataHcName = 'agile-ops-assign-users-dialog';
export const AgileOpsOrderItemsAssignDialog = ({
  dialogProps,
  assignUserProps,
}: AgileOpsOrderItemsAssignDialogProps) => {
  return (
    <Dialog
      {...dialogProps}
      title="Assign User"
      dataHcName={`${dataHcName}-dialog`}
      type="normal"
      theme={{
        DialogContent: styles.DialogContent,
      }}
    >
      <AgileOpsOrderItemsAssign
        {...assignUserProps}
        onCancel={() => {
          assignUserProps.onCancel?.();
          dialogProps.onClose();
        }}
        onSuccess={(assignee, orderItemIds) => {
          assignUserProps.onSuccess?.(assignee, orderItemIds);
          dialogProps.onClose();
        }}
        actionButtonsPortalId={DIALOG_ACTIONS_PORTAL_ID}
      />
    </Dialog>
  );
};
