import React from 'react';

import { DropdownOptionType } from '@hcs/design-system';
import {
  AnalysisIcon,
  ClockIcon,
  CommentIcon,
  HomePriceIcon,
  InspectionCorrectionIcon,
  ListAnalysisIcon,
  UserProfileIcon,
} from '@hcs/design-system';
import { CompleteIcon, ErrorIcon, RemoveIcon } from '@hcs/design-system';
import { InspectionsIcon } from '@hcs/design-system';
import { UpdateOptions, ValuationHistoryItemsTableColumns } from '@hcs/types';

export const ACTIVITY_LOG_EVENT_TYPES = {
  selectAll: {
    label: 'Select All',
    value: 'selectAll',
    icon: null,
  },
  approved: {
    label: 'Approved',
    value: 'approved',
    icon: <CompleteIcon dataHcName="om-approve-icon" color="neutral-dark-20" />,
  },
  Cancelled: {
    label: 'Cancelled',
    value: 'Cancelled',
    icon: <RemoveIcon dataHcName="om-cancelled-icon" color="neutral-dark-20" />,
  },
  CancelPending: {
    label: 'Cancel Pending',
    value: 'CancelPending',
    icon: <RemoveIcon dataHcName="om-cancelled-icon" color="neutral-dark-20" />,
  },
  comment: {
    label: 'Comment',
    value: 'comment',
    icon: <CommentIcon dataHcName="om-comment-icon" color="neutral-dark-20" />,
  },
  Complete: {
    label: 'Complete',
    value: 'Complete',
    icon: (
      <CompleteIcon dataHcName="om-complete-icon" color="neutral-dark-20" />
    ),
  },
  created: {
    label: 'Created',
    value: 'created',
    icon: <ClockIcon dataHcName="om-created-icon" color="neutral-dark-20" />,
  },
  inspectionApproved: {
    label: 'Inspection Approved',
    value: 'inspectionApproved',
    icon: <CompleteIcon dataHcName="om-approve-icon" color="neutral-dark-20" />,
  },
  InspectionCancelled: {
    label: 'Inspection Cancelled',
    value: 'InspectionCancelled',
    icon: <RemoveIcon dataHcName="om-cancelled-icon" color="neutral-dark-20" />,
  },
  InspectionCorrectionReview: {
    label: 'Inspection Correction Review',
    value: 'InspectionCorrectionReview',
    icon: (
      <InspectionCorrectionIcon dataHcName="om-inspection-correction-review-icon" />
    ),
  },
  inspectionNote: {
    label: 'Inspection Note',
    value: 'inspectionNote',
    icon: (
      <CommentIcon
        dataHcName="om-inspection-note-icon"
        color="neutral-dark-20"
      />
    ),
  },
  InspectionPending: {
    label: 'Inspection Pending',
    value: 'InspectionPending',
    icon: (
      <InspectionsIcon
        dataHcName="om-inspection-in-progress-icon"
        color="neutral-dark-20"
      />
    ),
  },
  InspectionReview: {
    label: 'Inspection Review',
    value: 'InspectionReview',
    icon: (
      <AnalysisIcon
        dataHcName="om-inspection-review-icon"
        color="neutral-dark-20"
      />
    ),
  },
  inspectionRevision: {
    label: 'Inspection Revision',
    value: 'InspectionRevision',
    icon: (
      <CommentIcon
        dataHcName="om-inspection-revision-icon"
        color="neutral-dark-20"
      />
    ),
  },
  internalNote: {
    label: 'Internal Note',
    value: 'internalNote',
    icon: (
      <CommentIcon dataHcName="om-internal-note-icon" color="neutral-dark-20" />
    ),
  },
  onHold: {
    label: 'On Hold',
    value: 'onHold',
    icon: <ErrorIcon dataHcName="om-on-hold-icon" color="neutral-dark-20" />,
  },
  reportModified: {
    label: 'Report Modified',
    value: 'reportModified',
    icon: (
      <ListAnalysisIcon
        dataHcName="om-report-modified-icon"
        color="neutral-dark-20"
      />
    ),
  },
  ReportPending: {
    label: 'Report Pending',
    value: 'ReportPending',
    icon: (
      <InspectionsIcon
        dataHcName="om-report-pending-icon"
        color="neutral-dark-20"
      />
    ),
  },
  RevisionRequested: {
    label: 'Revision Requested',
    value: 'RevisionRequested',
    icon: (
      <CommentIcon
        dataHcName="om-inspection-revision-icon"
        color="neutral-dark-20"
      />
    ),
  },
  userAssigned: {
    label: 'User Assigned',
    value: 'userAssigned',
    icon: (
      <UserProfileIcon
        dataHcName="om-user-assigned-icon"
        color="neutral-dark-20"
      />
    ),
  },
  ValuationReview: {
    label: 'Value Review',
    value: 'ValuationReview',
    icon: (
      <HomePriceIcon
        dataHcName="om-valuation-review-icon"
        color="neutral-dark-20"
      />
    ),
  },
  valueReportApproved: {
    label: 'Value Report Approved',
    value: 'valueReportApproved',
    icon: (
      <HomePriceIcon
        dataHcName="om-valuation-approved-icon"
        color="neutral-dark-20"
      />
    ),
  },
};

export const VALUATION_HISTORY_ITEM_COLUMNS_DEFAULT_ORDER = [
  ValuationHistoryItemsTableColumns.effective,
  ValuationHistoryItemsTableColumns.client,
  ValuationHistoryItemsTableColumns.source,
  ValuationHistoryItemsTableColumns.valueConclusion,
  ValuationHistoryItemsTableColumns.condition,
  ValuationHistoryItemsTableColumns.download,
];

export const AGILE_OPS_UPDATE_OPTIONS: DropdownOptionType<UpdateOptions>[] = [
  { label: 'Above/Below All Comps', value: UpdateOptions.AboveBelowAllComps },
  { label: 'No Clear Support', value: UpdateOptions.NoClearSupport },
  { label: 'Distance', value: UpdateOptions.Distance },
  { label: 'Diff Property Type', value: UpdateOptions.DiffPropertyType },
  {
    label: 'No High Similarity Comps',
    value: UpdateOptions.NoHighSimilarityComps,
  },
  {
    label: 'Missing Characteristics',
    value: UpdateOptions.MissingCharacteristics,
  },
  {
    label: 'Modified Characteristics',
    value: UpdateOptions.ModifiedCharacteristics,
  },
  { label: 'Modified Condition', value: UpdateOptions.ModifiedCondition },
  { label: 'Other Reason', value: UpdateOptions.OtherReason },
  { label: 'Clerical Update', value: UpdateOptions.ClericalUpdate },
];
