import React from 'react';
import classNames from 'classnames';

import { Dropdown } from '@hcs/design-system';
import { CompDetailsTypes } from '@hcs/types';

import styles from './OmAdminOrderItemCompsDetails.module.css';

interface Props {
  className?: string;
  compDetailsType: CompDetailsTypes;
  setCompDetailsType: (compDetailsType: CompDetailsTypes) => void;
}

const dataHcName = 'comps-details-dropdown';

export const CompsDetailsDropdown = ({
  className,
  compDetailsType,
  setCompDetailsType,
}: Props) => {
  return (
    <Dropdown
      dataHcName={`${dataHcName}`}
      className={classNames(styles.OmAdminOrderItemCompsDropdown, className)}
      theme={{
        OptionsContainer: classNames(styles.OptionsContainer, className),
      }}
      onSelect={setCompDetailsType}
      options={[
        {
          label: 'Competitive Closed Sales',
          value: 'compsSold',
        },
        {
          label: 'Competitive Listings',
          value: 'compsListing',
        },
      ]}
      value={compDetailsType}
    />
  );
};
