import { useQuery } from '@tanstack/react-query';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { useOmReviewerToken } from './useOmReviewerToken';

export const QUERY_KEY_OM_ADMIN_USER = 'QUERY_KEY_OM_ADMIN_USER';
export const useOmAdminUser = () => {
  useOmReviewerToken();
  return useQuery([QUERY_KEY_OM_ADMIN_USER], async () => {
    return OrderManagerReviewApi.fetchOmAdminUser();
  });
};
