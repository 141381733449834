import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemInspectionRevisionPayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_ORDER_ITEMS_ADMIN } from './useOrderItemsAdmin';

type UseOrderItemRevisionOptions = UseMutationOptions<
  void,
  AxiosError,
  OrderItemInspectionRevisionPayload,
  void
>;

export const useOrderItemInspectionRevision = (
  options?: UseOrderItemRevisionOptions
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    void,
    AxiosError,
    OrderItemInspectionRevisionPayload,
    void
  >(
    async (payload) => {
      return await OrderManagerReviewApi.orderItemsInspectionOnlyRevision(
        payload
      );
    },
    {
      ...options,
      onError(error, variables) {
        options?.onError?.(error, variables);
        toastOpen({
          type: 'error',
          title: error.message,
        });
      },
      onSuccess(response, variables) {
        options?.onSuccess?.(response, variables);
        queryClient.invalidateQueries([QUERY_KEY_ORDER_ITEMS_ADMIN]);
        toastOpen({
          type: 'success',
          title: 'Order revision request completed successfully',
        });
      },
    }
  );
};
