import React from 'react';

import {
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
  DialogProps,
} from '@hcs/design-system';

import {
  AgileOpsOrderItemsApprove,
  ApproveOrderItemProps,
} from './AgileOpsOrderItemsApprove';

export interface AgileOpsOrderItemsApproveDialogProps {
  approveOrderItemProps: ApproveOrderItemProps;
  dialogProps: Omit<DialogProps, 'dataHcName' | 'children'>;
}
const dataHcName = 'agile-ops-order-items-approve-dialog';
export const AgileOpsOrderItemsApproveDialog = ({
  dialogProps,
  approveOrderItemProps,
}: AgileOpsOrderItemsApproveDialogProps) => {
  return (
    <Dialog
      {...dialogProps}
      title={`Approve Report${
        approveOrderItemProps.orderItems.length === 1 ? '' : 's'
      }`}
      dataHcName={`${dataHcName}-dialog`}
      type="small"
    >
      <AgileOpsOrderItemsApprove
        {...approveOrderItemProps}
        onCancel={() => {
          approveOrderItemProps.onCancel?.();
          dialogProps.onClose();
        }}
        onSuccess={(v) => {
          approveOrderItemProps.onSuccess?.(v);
          dialogProps.onClose();
        }}
        actionButtonsPortalId={DIALOG_ACTIONS_PORTAL_ID}
      />
    </Dialog>
  );
};
