import { useEffect, useState } from 'react';

import { OrderItemStatus } from '@hcs/types';

import { useOmAdminOrderItem } from './useOmAdminOrderItem';
import { useOmAdminUser } from './useOmAdminUser';

export const useOmAdminOrderItemDisabled = (orderItemId: number) => {
  const [isNotAssignee, setIsNotAssignee] = useState(true);
  const [inActionable, setInActionable] = useState(true);
  const [isDisallowRevision, setIsDisallowRevision] = useState(true);
  const [orderComplete, setOrderComplete] = useState(false);
  const orderItemQuery = useOmAdminOrderItem(orderItemId);
  const { data: omAdminUser } = useOmAdminUser();

  useEffect(() => {
    omAdminUser?.id === orderItemQuery?.data?.assignee?.id
      ? setIsNotAssignee(false)
      : setIsNotAssignee(true);
  }, [orderItemQuery, omAdminUser]);

  useEffect(() => {
    orderItemQuery?.data?.status ===
      OrderItemStatus.InspectionCorrectionReview ||
    orderItemQuery?.data?.status === OrderItemStatus.ValuationReview ||
    orderItemQuery?.data?.status === OrderItemStatus.InspectionReview
      ? setInActionable(false)
      : setInActionable(true);
  }, [orderItemQuery]);

  useEffect(() => {
    orderItemQuery?.data?.status === OrderItemStatus.Complete
      ? setOrderComplete(true)
      : setOrderComplete(false);
  }, [orderItemQuery]);

  useEffect(() => {
    const pendingStates = [
      orderItemQuery?.data?.inspectionReviewPending,
      orderItemQuery?.data?.valueReportReviewPending,
    ];
    pendingStates.some((state) => state)
      ? setIsDisallowRevision(false)
      : setIsDisallowRevision(true);
  }, [orderItemQuery]);

  return {
    ...orderItemQuery,
    data: orderItemQuery.isInitialLoading
      ? undefined
      : { isNotAssignee, inActionable, orderComplete, isDisallowRevision },
  };
};
