import { useQuery } from '@tanstack/react-query';

import { OmAdminTokenResponse } from '@hcs/types';

import { OM_REVIEWER_LOCAL_STORAGE_TOKEN_KEY } from '../constants/orderManagerAdmin.constants';

export const QUERY_KEY_OM_REVIEWER_TOKEN = 'QUERY_KEY_OM_REVIEWER_TOKEN';
export const useOmReviewerToken = () =>
  useQuery<OmAdminTokenResponse | null>(
    [QUERY_KEY_OM_REVIEWER_TOKEN],
    async () => {
      // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
      // eslint-disable-next-line scanjs-rules/identifier_localStorage
      const storedTokenResponse = localStorage.getItem(
        OM_REVIEWER_LOCAL_STORAGE_TOKEN_KEY
      );
      return storedTokenResponse
        ? (JSON.parse(storedTokenResponse) as OmAdminTokenResponse)
        : null;
    }
  );
