import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';

import { useValuationHistoryData } from '../../hooks/useValuationHistoryData';
import { TabsContentWrapper } from '../OmAdminOrderItemTabSection/TabsContentWrapper';

import { ValuationHistoryChart } from './ValuationHistoryChart';
import { ValuationHistoryTable } from './ValuationHistoryTable';

interface Props {
  orderItemId: number;
}
const dataHcName = 'om-admin-order-item-valuation-history';
export const OmAdminOrderItemValuationHistory = ({ orderItemId }: Props) => {
  const { data, isSuccess, isError, isFetching } =
    useValuationHistoryData(orderItemId);

  const { chartConfig, tableConfig } = data;
  return (
    <TabsContentWrapper dataHcName={dataHcName}>
      {isFetching && (
        <LoadingSpinner
          dataHcName={`${dataHcName}-skeleton`}
          small
          absoluteCenter
        />
      )}
      {chartConfig.chartData[0]?.data &&
        tableConfig.tableRows.length > 0 &&
        isSuccess && (
          <>
            {chartConfig && <ValuationHistoryChart chartConfig={chartConfig} />}
            {tableConfig.tableRows.length > 0 && (
              <ValuationHistoryTable
                key={`${dataHcName}-table`}
                tableConfig={tableConfig}
              />
            )}
          </>
        )}
      {chartConfig.chartData[0]?.data &&
        tableConfig.tableRows.length === 0 &&
        isSuccess && <>No Valuation History Data Provided</>}
      {isError && <>Error Fetching Valuation History Data</>}
    </TabsContentWrapper>
  );
};
