import React from 'react';
import classNames from 'classnames';

import { Dialog, DIALOG_ACTIONS_PORTAL_ID } from '@hcs/design-system';
import { OmAdminOrderItem } from '@hcs/types';

import { AgileOpsOrderItemRevision } from '../AgileOpsOrderItemRevision/AgileOpsOrderItemRevision';

import styles from './AgileOpsOrderRevisionDialog.module.css';

interface Props {
  active: boolean;
  className?: string;
  orderItem: OmAdminOrderItem;
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
}
const dataHcName = 'agile-ops-order-revision-dialog';
export const AgileOpsOrderRevisionDialog = ({
  active,
  className,
  orderItem,
  onClose,
  onSuccess,
}: Props) => {
  return (
    <Dialog
      dataHcName={dataHcName}
      active={active}
      title="Inspection Revision Request"
      theme={{
        Dialog: classNames(styles.Dialog, className),
        DialogContent: styles.DialogSection,
      }}
      onClose={onClose}
      type="auto"
      noContentPadding
    >
      <section className={styles.DialogSection}>
        {orderItem && (
          <div className={styles.DialogContent}>
            <AgileOpsOrderItemRevision
              orderItem={orderItem}
              onCancel={onClose}
              onSuccess={() => {
                onClose();
                onSuccess?.();
              }}
              actionButtonsPortalId={DIALOG_ACTIONS_PORTAL_ID}
            />
          </div>
        )}
      </section>
    </Dialog>
  );
};
