import React from 'react';
import { format } from 'date-fns';

import { OrderItemActivity } from '@hcs/types';

import { ACTIVITY_LOG_EVENT_TYPES } from '../../constants/orderItem.constants';

import styles from './OmAdminOrderItemActivityLog.module.css';

interface Props {
  activity: OrderItemActivity | null;
}

const dataHcName = 'om-admin-order-item-activity-item';
export const ActivityItem = ({ activity }: Props) => {
  const activityTitle =
    ACTIVITY_LOG_EVENT_TYPES[
      activity?.metadata.title as keyof typeof ACTIVITY_LOG_EVENT_TYPES
    ];

  return (
    <div className={styles.ItemDetails}>
      {activityTitle?.icon ? (
        <div
          className={styles.ItemDetailsImg}
          data-hc-name={`${dataHcName}-details-icon`}
        >
          {activityTitle.icon}
        </div>
      ) : null}
      <div className={styles.ItemDetailsText}>
        <div className={styles.ItemDetailsLabelRow}>
          {activityTitle ? (
            <span
              className={styles.ItemDetailsLabel}
              data-hc-name={`${dataHcName}-details-label`}
            >
              {activityTitle.label}
            </span>
          ) : null}
          <span
            className={styles.ItemDetailsTime}
            data-hc-name={`${dataHcName}-timestamp`}
          >
            {activity?.timestamp
              ? format(new Date(activity.timestamp), 'h:mm aaa')
              : null}
          </span>
        </div>
        {activity?.metadata.comment ? (
          <div className={styles.ItemDetailsLabelRow}>
            {activity?.metadata.comment}
          </div>
        ) : null}
        {activity?.metadata.displayText ? (
          <div className={styles.ItemDetailsLabelRow}>
            {activity?.metadata.displayText}
          </div>
        ) : null}
        <div
          className={styles.ItemDetailsAuthor}
          data-hc-name={`${dataHcName}-author`}
        >
          {activity?.user?.email ? (
            <span>-- {activity.user?.email}</span>
          ) : (
            'System Generated'
          )}
        </div>
      </div>
    </div>
  );
};
