import React from 'react';

import { OrderItemActivity } from '@hcs/types';

import { ActivityItem } from '.';

interface Props {
  activity: OrderItemActivity;
}

export const ActivityItemNonComment = ({ activity }: Props) => (
  <ActivityItem activity={activity} />
);
