import { TableCellProps } from '@hcs/types';
import {
  OmValuationHistoryItem,
  ValuationHistoryItemsTableColumns,
} from '@hcs/types';

import { VALUATION_HISTORY_ITEM_CLIENT_CONFIG } from './Client';
import { VALUATION_HISTORY_ITEM_CONDITION_CONFIG } from './Condition';
import { VALUATION_HISTORY_ITEM_DOWNLOAD_CONFIG } from './Download';
import { VALUATION_HISTORY_ITEM_EFFECTIVE_CONFIG } from './Effective';
import { VALUATION_HISTORY_ITEM_SOURCE_CONFIG } from './Source';
import { VALUATION_HISTORY_ITEM_VALUE_CONCLUSION_CONFIG } from './ValueConclusion';

export interface ValuationHistoryItem {
  valuationHistoryItem: OmValuationHistoryItem;
}

export interface ValuationHistoryItemTableCellConfig {
  Display: ({ valuationHistoryItem }: ValuationHistoryItem) => JSX.Element;
  ContentCell: {
    ({ valuationHistoryItem }: ValuationHistoryItem): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
  label: string;
}

export const VALUATION_HISTORY_TABLE_CELLS_CONFIG: {
  [key in ValuationHistoryItemsTableColumns]: ValuationHistoryItemTableCellConfig;
} = {
  [ValuationHistoryItemsTableColumns.effective]:
    VALUATION_HISTORY_ITEM_EFFECTIVE_CONFIG,
  [ValuationHistoryItemsTableColumns.client]:
    VALUATION_HISTORY_ITEM_CLIENT_CONFIG,
  [ValuationHistoryItemsTableColumns.source]:
    VALUATION_HISTORY_ITEM_SOURCE_CONFIG,
  [ValuationHistoryItemsTableColumns.valueConclusion]:
    VALUATION_HISTORY_ITEM_VALUE_CONCLUSION_CONFIG,
  [ValuationHistoryItemsTableColumns.condition]:
    VALUATION_HISTORY_ITEM_CONDITION_CONFIG,
  [ValuationHistoryItemsTableColumns.download]:
    VALUATION_HISTORY_ITEM_DOWNLOAD_CONFIG,
};
