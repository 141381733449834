import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { NoDataIcon } from '@hcs/design-system';
import { OrderItemQcFlag } from '@hcs/types';

import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { showDate } from '../../utils/omAdminOrderItems.utils';
import { TabsContentWrapper } from '../OmAdminOrderItemTabSection/TabsContentWrapper';

import { QcFlagItem } from './QcFlagItem';

import styles from './OmAdminOrderItemFlags.module.css';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-item-flags';
export const OmAdminOrderItemFlags = ({ orderItemId }: Props) => {
  const { data: orderItem, isFetched } = useOmAdminOrderItem(orderItemId);
  const { qcFlags } = orderItem || {};
  return (
    <TabsContentWrapper dataHcName={dataHcName}>
      {isFetched && qcFlags?.length ? (
        qcFlags.map((flag: OrderItemQcFlag, idx: number) => (
          <QcFlagItem
            key={flag.id}
            {...flag}
            showDate={showDate(flag.createdAt, qcFlags[idx - 1]?.createdAt)}
          />
        ))
      ) : (
        <div className={styles.NullFlagItems}>
          <span className={styles.FlagIcon}>
            <NoDataIcon size="xl" color="neutral-dark-20" />
          </span>
          <h3>No flags triggered for this report</h3>
        </div>
      )}
      {!isFetched ? (
        <LoadingSpinner
          dataHcName={`${dataHcName}-skeleton`}
          small
          absoluteCenter
        />
      ) : null}
    </TabsContentWrapper>
  );
};
