import {
  OmAdminOrderItem,
  OrderItemTableColumns,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';

import { OM_ADMIN_ORDER_ITEMS_ADDRESS_CONFIG } from './Address';
import { OM_ADMIN_ORDER_ITEMS_ASSIGNEE_CONFIG } from './Assignee';
import { OM_ADMIN_ORDER_ITEMS_CREATED_CONFIG } from './Created';
import { OM_ADMIN_ORDER_ITEMS_CURRENT_STATUS_CONFIG } from './CurrentStatus';
import { OM_ADMIN_ORDER_ITEMS_DUE_DATE_CONFIG } from './DueDate';
import { OM_ADMIN_ORDER_ITEMS_INSPECTION_PARTNER_CONFIG } from './InspectionPartner';
import { OM_ADMIN_ORDER_ITEMS_MESSAGES_CONFIG } from './Messages';
import { OM_ADMIN_ORDER_ITEMS_ORDER_NAME_CONFIG } from './OrderName';
import { OM_ADMIN_ORDER_ITEMS_ORDER_ORG_ID_CONFIG } from './OrderOrgId';
import { OM_ADMIN_ORDER_ITEMS_ORDER_TYPE_ID_CONFIG } from './OrderTypeId';
import { OM_ADMIN_ORDER_ITEMS_QC_FLAGS_CONFIG } from './QcFlagsUnresolvedCount';
import { OM_ADMIN_ORDER_ITEMS_STATUS_CONFIG } from './Status';

export interface DisplayProps {
  orderItem?: OmAdminOrderItem;
}

export interface OmAdminOrderItemsTableCellConfig {
  Display: ({ orderItem }: DisplayProps) => JSX.Element;
  HeaderCell: {
    ({ ...tableHeaderCellProps }): JSX.Element;
    defaultProps: Partial<TableHeaderCellProps>;
  };
  ContentCell: {
    ({ orderItem }: { orderItem: OmAdminOrderItem }): JSX.Element;
    defaultProps: Partial<TableCellProps>;
  };
  label: string;
}

export const OM_ADMIN_ORDER_ITEMS_TABLE_CELLS_CONFIG: {
  [key in OrderItemTableColumns]: OmAdminOrderItemsTableCellConfig;
} = {
  [OrderItemTableColumns.address]: OM_ADMIN_ORDER_ITEMS_ADDRESS_CONFIG,
  [OrderItemTableColumns.assigneeName]: OM_ADMIN_ORDER_ITEMS_ASSIGNEE_CONFIG,
  [OrderItemTableColumns.createdAt]: OM_ADMIN_ORDER_ITEMS_CREATED_CONFIG,
  [OrderItemTableColumns.currentStatusDate]:
    OM_ADMIN_ORDER_ITEMS_CURRENT_STATUS_CONFIG,
  [OrderItemTableColumns.dueDate]: OM_ADMIN_ORDER_ITEMS_DUE_DATE_CONFIG,
  [OrderItemTableColumns.hasActionableMessages]:
    OM_ADMIN_ORDER_ITEMS_MESSAGES_CONFIG,
  [OrderItemTableColumns.inspectionPartnerName]:
    OM_ADMIN_ORDER_ITEMS_INSPECTION_PARTNER_CONFIG,
  [OrderItemTableColumns.orderName]: OM_ADMIN_ORDER_ITEMS_ORDER_NAME_CONFIG,
  [OrderItemTableColumns.orderTypeId]:
    OM_ADMIN_ORDER_ITEMS_ORDER_TYPE_ID_CONFIG,
  [OrderItemTableColumns.orderOrganizationId]:
    OM_ADMIN_ORDER_ITEMS_ORDER_ORG_ID_CONFIG,
  [OrderItemTableColumns.qcFlagsUnresolvedCount]:
    OM_ADMIN_ORDER_ITEMS_QC_FLAGS_CONFIG,
  [OrderItemTableColumns.status]: OM_ADMIN_ORDER_ITEMS_STATUS_CONFIG,
};
