import { CompDetailsTypes, CompsTabData } from '@hcs/types';
import { snakeCaseToCamelCase } from '@hcs/utils';

import { useOmAdminOrderItemCompetitiveClosedSales } from './useOmAdminOrderItemCompetitiveClosedSales';
import { useOmAdminOrderItemCompetitiveListings } from './useOmAdminOrderItemCompetitiveListings';
import { useOmAdminOrderItemReport } from './useOmAdminOrderItemReport';

export const QUERY_KEY_OM_ADMIN_COMPS_DETAILS =
  'QUERY_KEY_OM_ADMIN_COMPS_DETAILS';
export const useOmAdminCompsDetails = (
  orderItemId: number,
  compDetailsType: CompDetailsTypes
) => {
  const reportDataQuery = useOmAdminOrderItemReport(orderItemId);

  const { data: reportData } = reportDataQuery;
  const { metadata } = reportData || {};
  const selectedAvm = snakeCaseToCamelCase(metadata?.selectedAvm || '');
  const avm = reportData?.[selectedAvm as 'adjustedAvm'] || reportData?.avm;
  const avmVal = avm?.value;
  const avmMin = avm?.minVal;
  const avmMax = avm?.maxVal;

  const subjectDetails = {
    type: 'Subject',
    property: 'Subject',
    currentValue: Number(avmVal),
    currentValueColor: 'var(--secondary-10)',
    soldPrice: Number(avmVal),
    soldPriceColor: 'var(--secondary-10)',
  };

  const compClosedSalesQuery =
    useOmAdminOrderItemCompetitiveClosedSales(orderItemId);
  const compListingsQuery = useOmAdminOrderItemCompetitiveListings(orderItemId);

  function buildCompsData(type: CompDetailsTypes, data: CompsTabData[]) {
    const compsData = data?.map((comp, idx: number) => {
      const { propertyDetails } = comp || {};
      return {
        type:
          type === 'compsSold'
            ? 'Competitive Closed Sales'
            : 'Competitive Listings',
        property: String(idx + 1),
        currentValue: Number(propertyDetails['propertyValue.valueMean']),
        currentValueColor: 'var(--neutral-dark-10)',
        soldPrice:
          type === 'compsSold'
            ? Number(propertyDetails['complexFieldsSale.lastClosePrice'])
            : Number(propertyDetails['complexFieldsSale.currentListingPrice']),
        soldPriceColor: 'var(--neutral-dark-40)',
      };
    });

    return [subjectDetails, ...compsData];
  }

  function getCompsData(type: CompDetailsTypes) {
    return type === 'compsSold'
      ? buildCompsData(type, compClosedSalesQuery.data || [])
      : buildCompsData(type, compListingsQuery.data || []);
  }

  const chartData = getCompsData(compDetailsType);

  return { chartData, avmVal, avmMin, avmMax };
};
