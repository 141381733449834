import { useEffect, useState } from 'react';

import { CompsTabData } from '@hcs/types';

import { pinkmanCompToFlatPropertyDetails } from '../utils/orderItemReport.utils';

import { useOmAdminOrderItemReport } from './useOmAdminOrderItemReport';

// TODO: fill that in for OmReport, type defines only what using off the Report if everything rendered by comps is covered in property details as some things might not map in schema.

export const useOmAdminOrderItemCompetitiveClosedSales = (
  orderItemId: number
) => {
  const [compsData, setCompsData] = useState<CompsTabData[] | undefined>(
    undefined
  );
  const orderItemReportQuery = useOmAdminOrderItemReport(orderItemId);

  useEffect(() => {
    if (orderItemReportQuery.isInitialLoading && orderItemReportQuery.data) {
      setCompsData(undefined);
    } else {
      const comps = orderItemReportQuery.data?.competitiveClosedSales.map(
        pinkmanCompToFlatPropertyDetails
      );
      setCompsData(comps);
    }
  }, [
    orderItemReportQuery.isInitialLoading,
    orderItemReportQuery.data?.competitiveClosedSales,
  ]);

  return {
    ...orderItemReportQuery,
    data: compsData,
  };
};
