import React from 'react';

import { CardTabs, CardTabsProps } from '@hcs/design-system';

import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { OmAdminOrderItemActivityLog } from '../OmAdminOrderItemActivityLog';
import { OmAdminOrderItemCompsDetails } from '../OmAdminOrderItemCompsDetails';
import { OmAdminOrderItemFlags } from '../OmAdminOrderItemFlags';
import { OmAdminOrderItemValuationHistory } from '../OmAdminOrderItemValuationHistory';

import { TabsLabel } from './TabsLabel';

import styles from './OmAdminOrderItemTabSection.module.css';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-item-tab-section';

export const OmAdminOrderItemTabSection = ({ orderItemId }: Props) => {
  const { data: orderItem } = useOmAdminOrderItem(orderItemId);

  const tabs: CardTabsProps['tabs'] = [
    {
      tabId: 'flags',
      label: (
        <TabsLabel
          displayText="Flags"
          showIndicator={!!orderItem?.qcFlagsUnresolvedCount}
        />
      ),
      noPadding: false,
      content: <OmAdminOrderItemFlags orderItemId={orderItemId} />,
      scrollable: true,
    },
    {
      tabId: 'activity',
      label: (
        <TabsLabel
          displayText="Activity"
          showIndicator={!!orderItem?.hasActionableMessages}
        />
      ),
      noPadding: false,
      content: <OmAdminOrderItemActivityLog orderItemId={orderItemId} />,
      scrollable: true,
    },
    {
      tabId: 'comps',
      label: <TabsLabel displayText="Comps" />,
      noPadding: false,
      content: <OmAdminOrderItemCompsDetails orderItemId={orderItemId} />,
      scrollable: true,
    },
    {
      tabId: 'values',
      label: <TabsLabel displayText="Values" />,
      noPadding: false,
      content: <OmAdminOrderItemValuationHistory orderItemId={orderItemId} />,
      scrollable: true,
    },
  ];

  return (
    <CardTabs
      dataHcName={dataHcName}
      searchParamId={'OmAdminOrderItemSidebar'}
      tabs={tabs}
      active="flags"
      className={styles.CardTabs}
      flexTabs
    />
  );
};
