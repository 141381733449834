import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { ButtonProps } from '@hcs/design-system';
import { Toggle, ToggleOption } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { AgileSuitePdfOrderTypeIds, OrderItemStatus } from '@hcs/types';
import { InspectionViewState } from '@hcs/types';
import { formatTimeRemaining, nowKeywordToDate } from '@hcs/utils';
import { NULL_VALUE, ORDER_ITEM_STATUSES } from '@hcs/utils';

import { useOmAdminUser } from '../../hooks';
import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { useOmAdminOrderItemDisabled } from '../../hooks/useOmAdminOrderItemDisabled';
import { useSubmitOmAdminOrderItemInspectionForm } from '../../hooks/useSubmitOmAdminOrderItemInspectionForm';
import { ApproveOrderItemProps } from '../AgileOpsOrderItemsApprove';
import { AgileOpsOrderItemsApproveDialog } from '../AgileOpsOrderItemsApprove/AgileOpsOrderItemsApproveDialog';
import { AgileOpsOrderItemsAssignDialog } from '../AgileOpsOrderItemsAssign/AgileOpsOrderItemsAssignDialog';
import { AgileOpsOrderUpdateDialog } from '../AgileOpsOrderItemUpdate/AgileOpsOrderUpdateDialog';
import { AgileOpsOrderRevisionDialog } from '../AgileOpsOrderRevisionDialog/AgileOpsOrderRevisionDialog';

import styles from './OmAdminOrderItemDetails.module.css';

export interface OmAdminOrderItemDetailsProps {
  orderItemId: number;
  defaultView: InspectionViewState | null;
  handleToggleView: () => void;
  onRevisionSuccess?: VoidFunction;
  approveOrderItemProps?: Omit<ApproveOrderItemProps, 'orderItems'>;
}

const TOGGLE_OPTIONS: ToggleOption<InspectionViewState>[] = [
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'Edit',
    value: 'form',
  },
];

const dataHcName = 'om-admin-order-item-details';

export const OmAdminOrderItemDetails = ({
  orderItemId,
  defaultView,
  onRevisionSuccess,
  handleToggleView,
  approveOrderItemProps,
}: OmAdminOrderItemDetailsProps) => {
  const { data: orderItem } = useOmAdminOrderItem(orderItemId);
  const { data: omAdminUser } = useOmAdminUser();
  const [timeRemaining, setTimeRemaining] = useState<string | null>(NULL_VALUE);
  const [timeInQueue, setTimeInQueue] = useState<string | null>(NULL_VALUE);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  const { data: orderItemDisabled } = useOmAdminOrderItemDisabled(orderItemId);
  const { active, handleOpen, handleClose } = useActiveState();
  const {
    active: activeAgileOpsOrderAssignmentDialog,
    handleOpen: handleOpenOrderAssignmentDialog,
    handleClose: handleCloseOrderAssignmentDialog,
  } = useActiveState();
  const {
    active: activeAgileOpsOrderApproveDialog,
    handleOpen: handleOpenOrderApproveDialog,
    handleClose: handleCloseOrderApproveDialog,
  } = useActiveState();
  const {
    active: activeAgileOpsOrderRevisionDialog,
    handleOpen: handleOpenOrderRevisionDialog,
    handleClose: handleCloseOrderRevisionDialog,
  } = useActiveState();
  const {
    active: activeAgileOpsOrderUpdateDialog,
    handleOpen: handleOpenOrderUpdateDialog,
    handleClose: handleCloseOrderUpdateDialog,
  } = useActiveState();

  const submitOmAdminFormMutation = useSubmitOmAdminOrderItemInspectionForm();
  let inspectionIdInt: number | null = null;
  if (orderItem && orderItem.inspectionId) {
    inspectionIdInt = orderItem.inspectionId;
  }

  const isBPOItem =
    orderItem?.orderTypeId ===
    AgileSuitePdfOrderTypeIds.BrokerPriceOpinionExterior;
  const handleSaveForm = () => {
    const form = document.querySelector('form');
    const formData = form ? new FormData(form) : null;
    submitOmAdminFormMutation.mutate({ inspectionIdInt, formData });
  };

  const defaultActionsButtons: ButtonProps[] = [
    {
      dataHcName: `${dataHcName}-assign-user`,
      label: 'Assign User',
      onClick: () => {
        handleOpenOrderAssignmentDialog();
      },
      primary: false,
      disabled:
        orderItemDisabled?.orderComplete ||
        !omAdminUser?.permissions?.canAssignReviewer,
    },
    {
      dataHcName: `${dataHcName}-order-approve`,
      label: 'Approve Report',
      onClick: () => {
        handleOpenOrderApproveDialog();
      },
      primary: false,
      disabled:
        orderItemDisabled?.isNotAssignee ||
        orderItemDisabled?.inActionable ||
        orderItemDisabled?.orderComplete,
      className: styles.EndButton,
    },
    {
      dataHcName: `${dataHcName}-request-revision`,
      label: 'Request Revision',
      onClick: () => {
        handleOpenOrderRevisionDialog();
      },
      primary: false,
      disabled:
        orderItemDisabled?.isNotAssignee ||
        orderItemDisabled?.inActionable ||
        orderItemDisabled?.orderComplete ||
        orderItemDisabled?.isDisallowRevision,
    },
  ];

  const saveButton = [
    {
      dataHcName: `${dataHcName}-save-form`,
      label: 'Save Form',
      onClick: handleSaveForm,
      primary: true,
      className: classNames(styles.SaveFormButton),
      disabled:
        orderItemDisabled?.isNotAssignee || orderItemDisabled?.orderComplete,
    },
  ];

  const createButtons = useCallback(() => {
    const actionButtons: ButtonProps[] = [];
    if (defaultView === 'pdf') {
      actionButtons.push(
        {
          dataHcName: 'action-button-name',
          label: 'Edit in PEXP',
          primary: false,
          onClick: () => {
            orderItem?.valueReportLink &&
              // SECURITY - Known internal URL @fcarvajal
              // eslint-disable-next-line security/detect-non-literal-fs-filename
              window.open(orderItem?.valueReportLink, 'tab')?.focus();
          },
          disabled:
            !orderItem?.valueReportLink ||
            orderItemDisabled?.isNotAssignee ||
            orderItemDisabled?.orderComplete,
        },
        {
          dataHcName: 'action-button-name',
          label: 'Update Report',
          onClick: () => {
            handleOpenOrderUpdateDialog();
          },
          primary: false,
          disabled:
            orderItemDisabled?.isNotAssignee ||
            orderItemDisabled?.orderComplete,
        }
      );
    }

    // Add default buttons
    actionButtons.push(
      ...(!orderItemDisabled?.inActionable ? defaultActionsButtons : []),
      ...(defaultView === 'form' ? saveButton : [])
    );

    return actionButtons;
  }, [defaultView, orderItemDisabled, orderItem]);

  useEffect(() => {
    if (
      orderItem?.dueDate &&
      orderItem?.status !== OrderItemStatus.Cancelled &&
      orderItem?.status !== OrderItemStatus.Complete
    ) {
      setTimeRemaining(
        formatTimeRemaining(
          nowKeywordToDate('now'),
          nowKeywordToDate(orderItem.dueDate)
        )
      );
    }
  }, [orderItem?.dueDate, orderItem?.status]);

  useEffect(() => {
    if (
      orderItem?.currentStatusDate &&
      orderItem?.status !== OrderItemStatus.Cancelled &&
      orderItem?.status !== OrderItemStatus.Complete
    ) {
      setTimeInQueue(
        formatTimeRemaining(
          nowKeywordToDate(orderItem.currentStatusDate),
          nowKeywordToDate('now')
        )
      );
    }
  }, [orderItem?.currentStatusDate]);

  useEffect(() => {
    if (timeRemaining !== null && timeRemaining[0] === '-') {
      setIsOverdue(true);
    }
  }, [timeRemaining]);

  return orderItem ? (
    <>
      <h2
        data-hc-name={`${dataHcName}-address`}
        className={styles.DetailsHeading}
      >
        {`${orderItem.address} ${orderItem.city}, ${orderItem.state} ${orderItem.zipcode}`}
        <DirectionalChevron
          dataHcName={`${dataHcName}-icon`}
          direction={active ? 'up' : 'down'}
          className={styles.Chevron}
          onClick={active ? handleClose : handleOpen}
        />
      </h2>
      <div
        style={{
          display: active ? 'block' : 'none',
        }}
      >
        <div className={styles.Details}>
          <div className={styles.DetailsSection}>
            <div className={styles.DetailsDataGroup}>
              <div className={styles.DetailsSpace}>
                <label>Status:</label>
                <label>Inspected:</label>
              </div>
              <div className={styles.DetailsSpace}>
                <span>{ORDER_ITEM_STATUSES[orderItem.status]}</span>
                {orderItem.inspectionDate ? (
                  <span>{`${format(
                    new Date(orderItem.inspectionDate),
                    'P'
                  )} by ${orderItem.inspectionPartnerName}`}</span>
                ) : (
                  <span>{NULL_VALUE}</span>
                )}
              </div>
            </div>
            <div className={styles.DetailsDataGroup}>
              <div className={styles.DetailsSpace}>
                <label>Due:</label>
                <label>Queue Time:</label>
              </div>
              <div className={styles.DetailsSpace}>
                <span className={classNames({ [styles.isOverdue]: isOverdue })}>
                  {timeRemaining}
                </span>
                <span>{timeInQueue}</span>
              </div>
            </div>
            <div className={styles.DetailsDataGroup}>
              <div className={styles.DetailsSpace}>
                <label>Assignee:</label>
                <label>Order Item:</label>
              </div>
              <div className={styles.DetailsSpace}>
                {orderItem.assignee ? (
                  <span>{orderItem.assignee?.email}</span>
                ) : (
                  <span>{NULL_VALUE}</span>
                )}
                <span>#{orderItemId}</span>
              </div>
            </div>
            <div className={styles.DetailsDataGroup}>
              <div className={styles.DetailsSpace}>
                <label>PDF Last Refreshed:</label>
              </div>
              <div className={styles.DetailsSpace}>
                {orderItem.valueReportUpdatedAt ? (
                  <span>{orderItem.valueReportUpdatedAt}</span>
                ) : (
                  <span>{NULL_VALUE}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ActionButtonsWrapper}>
        {orderItem?.status &&
          (orderItem?.status === OrderItemStatus.ValuationReview ||
            orderItem?.status === OrderItemStatus.Complete) && (
            <Toggle<InspectionViewState | null>
              dataHcName={`${dataHcName}-toggle`}
              value={defaultView}
              options={TOGGLE_OPTIONS}
              onChange={handleToggleView}
              className={styles.Toggle}
            />
          )}
        <ActionButtons
          className={styles.ActionButtons}
          actions={createButtons()}
          dataHcName={`${dataHcName}-action-buttons`}
          noStretch
        />
      </div>
      <AgileOpsOrderItemsAssignDialog
        assignUserProps={{
          orderItems: [orderItem],
          valueReportId: isBPOItem ? orderItem?.valueReportId : undefined,
        }}
        dialogProps={{
          active: activeAgileOpsOrderAssignmentDialog,
          onClose: handleCloseOrderAssignmentDialog,
        }}
      />
      <AgileOpsOrderItemsApproveDialog
        approveOrderItemProps={{
          ...approveOrderItemProps,
          orderItems: [orderItem],
        }}
        dialogProps={{
          active: activeAgileOpsOrderApproveDialog,
          onClose: handleCloseOrderApproveDialog,
        }}
      />
      <AgileOpsOrderRevisionDialog
        orderItem={orderItem}
        onSuccess={onRevisionSuccess}
        active={activeAgileOpsOrderRevisionDialog}
        onClose={handleCloseOrderRevisionDialog}
      />
      <AgileOpsOrderUpdateDialog
        orderItem={orderItem}
        active={activeAgileOpsOrderUpdateDialog}
        onClose={handleCloseOrderUpdateDialog}
      />
    </>
  ) : (
    <LoadingSpinner dataHcName={dataHcName} small absoluteCenter />
  );
};
