import React from 'react';

import { LoadingSpinner } from '@hcs/design-system';
import { InspectionViewState } from '@hcs/types';

import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { OmAdminOrderItemForm } from '../OmAdminOrderItemForm';
import { OmAdminOrderItemPdf } from '../OmAdminOrderItemPdf';

import styles from './OmAdminOrderItemInspection.module.css';

interface Props {
  orderItemId: number;
  defaultView: InspectionViewState | null;
}

const dataHcName = 'om-admin-order-item-inspection';
export const OmAdminOrderItemInspection = ({
  orderItemId,
  defaultView,
}: Props) => {
  const { data: orderItem, isFetching } = useOmAdminOrderItem(orderItemId);

  return (
    <div data-hc-name={dataHcName} className={styles.InspectionWrapper}>
      {orderItem && (
        <>
          {defaultView === 'pdf' && (
            <OmAdminOrderItemPdf
              dataHcName={`${dataHcName}-pdf`}
              orderItemId={orderItemId}
              orderId={orderItem.orderId}
            />
          )}
          {defaultView === 'form' && orderItem.inspectionId && (
            <OmAdminOrderItemForm
              inspectionId={orderItem.inspectionId}
              orderItemId={orderItemId}
            />
          )}
        </>
      )}
      {isFetching && (
        <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} absoluteCenter />
      )}
      {!defaultView && <div>Inspection not found</div>}
    </div>
  );
};
