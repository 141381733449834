import React from 'react';
import { Navigate } from 'react-router';
import { RouteObject } from 'react-router-dom';

import { LayoutContent } from '@hcs/design-system';
import { LayoutGlobalHeaderChildren } from '@hcs/design-system';
import {
  APP_CONFIG_AGILE_OPS_COMMAND,
  VIEW_PATHS_AGILE_OPS_COMMAND,
} from '@hcs/hc-internal-apps';
import { OrderUpdatesProvider } from '@hcs/order-manager';

import { PartnerToolsApplicationPageRedirect } from '../../../auth-redirects/PartnerToolsPageRedirect';
import { PartnerToolsGlobalHeader } from '../../../navigation/PartnerToolsGlobalHeader';
import { AgileOpsNav } from '../navigation/AgileOpsNav/AgileOpsNav';
import { AgileOpsAllAddressesPage, AgileOpsReviewQueuePage } from '../pages';
import AgileOpsOrderItemPage from '../pages/AgileOpsOrderItemPage';

export const agileOpsRoutes = (): RouteObject[] => [
  {
    path: APP_CONFIG_AGILE_OPS_COMMAND.rootPath,
    element: (
      <PartnerToolsApplicationPageRedirect>
        <LayoutGlobalHeaderChildren header={<PartnerToolsGlobalHeader />}>
          <AgileOpsNav />
          <OrderUpdatesProvider>
            <LayoutContent />
          </OrderUpdatesProvider>
        </LayoutGlobalHeaderChildren>
      </PartnerToolsApplicationPageRedirect>
    ),
    children: [
      {
        path: VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE,
        element: <AgileOpsReviewQueuePage />,
      },
      {
        path: VIEW_PATHS_AGILE_OPS_COMMAND.ALL_ADDRESSES,
        element: <AgileOpsAllAddressesPage />,
      },
      {
        path: VIEW_PATHS_AGILE_OPS_COMMAND.ORDER_ITEM,
        element: <AgileOpsOrderItemPage />,
      },
      {
        path: APP_CONFIG_AGILE_OPS_COMMAND.rootPath,
        element: (
          <Navigate to={VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE} replace />
        ),
      },
    ],
  },
];
