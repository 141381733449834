import React, { useState } from 'react';

import { TextArea } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { OmAdminOrderItem } from '@hcs/types';

import { useOrderItemInspectionRevision } from '../../hooks/useOrderItemInspectionRevision';
import { useOrderItemRevision } from '../../hooks/useOrderItemRevision';

type CallbackProp = (orderItems: OmAdminOrderItem[]) => void;
export interface RevisionOrderItemProps {
  orderItem: OmAdminOrderItem;
  className?: string;
  actionButtonsPortalId?: string;
  onSubmit?: CallbackProp;
  onSuccess?: CallbackProp;
  onCancel?: VoidFunction;
}
const dataHcName = 'agile-ops-order-item-revision';
export const AgileOpsOrderItemRevision = ({
  orderItem,
  actionButtonsPortalId,
  onCancel,
  onSubmit,
  onSuccess,
}: RevisionOrderItemProps) => {
  const [value, setValue] = useState('');
  const reviseOrderItemMutation = useOrderItemRevision({
    onSuccess: () => {
      onSuccess?.([orderItem]);
    },
  });
  const reviseOrderItemInspectionMutation = useOrderItemInspectionRevision({
    onSuccess: () => {
      onSuccess?.([orderItem]);
    },
  });

  const handleSubmit = () => {
    if (orderItem.inspectionReviewPending) {
      reviseOrderItemInspectionMutation.mutate({
        inspectionId: orderItem.inspectionId,
        message: value,
      });
      onSubmit?.([orderItem]);
    }
    if (orderItem.valueReportReviewPending) {
      reviseOrderItemMutation.mutate({
        orderItemId: orderItem.valueReportId,
        message: value,
      });
      onSubmit?.([orderItem]);
    }
  };

  return (
    <>
      <TextArea value={value} dataHcName={dataHcName} onChange={setValue} />
      <ActionButtons
        portalIdRender={actionButtonsPortalId}
        dataHcName={`${dataHcName}-actions`}
        actions={[
          {
            dataHcName: `${dataHcName}-cancel`,
            label: 'Cancel',
            onClick: onCancel,
            secondary: true,
          },
          {
            dataHcName: `${dataHcName}-submit`,
            label: 'Save',
            disabled: reviseOrderItemMutation.isLoading || !value,
            loading: reviseOrderItemMutation.isLoading,
            onClick: handleSubmit,
          },
        ]}
      />
    </>
  );
};
