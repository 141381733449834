import { useQuery } from '@tanstack/react-query';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const QUERY_KEY_OM_ADMIN_PDF_DOWNLOAD =
  'QUERY_KEY_OM_ADMIN_PDF_DOWNLOAD';
export const useOmAdminOrderItemPdfDownload = (
  orderItemId: number,
  orderId: number,
  enabled = true
) => {
  const fetchPdfData = async () => {
    return await OrderManagerReviewApi.fetchOmAdminOrderItemPdfDownload(
      orderItemId,
      orderId
    );
  };

  return useQuery(
    [QUERY_KEY_OM_ADMIN_PDF_DOWNLOAD, orderItemId, orderId],
    fetchPdfData,
    { enabled }
  );
};
