import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { AxiosAccessTokenClientJwt } from '@hcs/http-clients';
import {
  OmAdminTokenResponse,
  OmAdminUser,
  OmReviewAssigneesParams,
} from '@hcs/types';
import {
  OmAdminActivityLogNewMessagePayload,
  OmAdminOrderItem,
  OmAdminOrderItemParams,
  OrderItemActivity,
  OrderItemActivityActionRequiredPayload,
  OrderItemActivityCommentPayload,
  OrderItemApprovePayload,
  OrderItemApproveResponse,
  OrderItemAssignee,
  OrderItemAssignPayload,
  OrderItemForm,
  OrderItemInspectionImagePayload,
  OrderItemInspectionRevisionPayload,
  OrderItemOrganizations,
  OrderItemPdfDownload,
  OrderItemRevisionPayload,
  OrderItemUpdatePayload,
  OrderItemValuationHistory,
} from '@hcs/types';
import {
  convertUrlToPrPreview,
  ORDER_MANAGER_API_WS_URL,
  ORDER_MANAGER_REVIEW_API_URL,
} from '@hcs/urls';
import { makePaginatedResponseData } from '@hcs/utils';
import { snakeCaseToCamelCase } from '@hcs/utils';

import { SubmitInspectionFormResponse } from '../hooks/useSubmitOmAdminOrderItemInspectionForm';
import { UpdateInspectionImageResponse } from '../hooks/useUpdateOmAdminOrderItemInspectionImage';
import { OmAdminActivityCommentResponse } from '../hooks/useUpdateOrderItemCommentActionRequired';
import { prepOmReviewAssigneesParamsForApi } from '../utils';
import { prepOmAdminOrderItemParamsForApi } from '../utils/omAdminOrderItems.utils';

export const OrderManagerReviewApi = {
  fetchOrderItems: async (
    params?: OmAdminOrderItemParams,
    signal?: AbortSignal
  ) => {
    const response = await AxiosAccessTokenClientJwt.get<OmAdminOrderItem[]>(
      `${ORDER_MANAGER_REVIEW_API_URL}/order-items/`,
      {
        params: params ? prepOmAdminOrderItemParamsForApi(params) : undefined,
        signal,
      }
    );
    return makePaginatedResponseData(
      response,
      {
        page: params?.page || 1,
        pageSize: params?.pageSize || 25,
      },
      { camelCaseKeys: true }
    );
  },
  fetchAgileOpsAssignees: async (params?: OmReviewAssigneesParams) => {
    const response = await AxiosAccessTokenClientJwt.get<OrderItemAssignee[]>(
      `${ORDER_MANAGER_REVIEW_API_URL}/assignees/`,
      {
        params: params ? prepOmReviewAssigneesParamsForApi(params) : undefined,
      }
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOmAdminUser: async () => {
    const response = await AxiosAccessTokenClientJwt.get<OmAdminUser>(
      `${ORDER_MANAGER_REVIEW_API_URL}/user/`
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  orderItemsApprove: async (payload: OrderItemApprovePayload) => {
    const response =
      await AxiosAccessTokenClientJwt.post<OrderItemApproveResponse>(
        `${ORDER_MANAGER_REVIEW_API_URL}/order-items/approve/`,
        snakecaseKeys(payload, { deep: true })
      );
    return camelcaseKeys(response.data, { deep: true });
  },
  orderItemsAssign: async (payload: OrderItemAssignPayload) => {
    await AxiosAccessTokenClientJwt.post<void>(
      `${ORDER_MANAGER_REVIEW_API_URL}/order-items/assign/`,
      snakecaseKeys(payload, { deep: true })
    );
    return;
  },
  orderItemsRevision: async (payload: OrderItemRevisionPayload) => {
    await AxiosAccessTokenClientJwt.post<OrderItemRevisionPayload>(
      `${ORDER_MANAGER_REVIEW_API_URL}/value-report-orders/review/${payload.orderItemId}/`,
      snakecaseKeys(
        { action: 'INSPECTION_REVISION', message: payload.message },
        { deep: true }
      )
    );
  },
  orderItemsInspectionOnlyRevision: async (
    payload: OrderItemInspectionRevisionPayload
  ) => {
    await AxiosAccessTokenClientJwt.post<OrderItemInspectionRevisionPayload>(
      `${ORDER_MANAGER_REVIEW_API_URL}/inspections/review/${payload.inspectionId}`,
      snakecaseKeys(
        { action: 'FAIL', message: payload.message },
        { deep: true }
      )
    );
  },
  regenerateOrderItemValueReport: async (payload: OrderItemUpdatePayload) => {
    await AxiosAccessTokenClientJwt.post<void>(
      `${ORDER_MANAGER_REVIEW_API_URL}/value-report-orders/review/${payload.valueReportId}/regenerate/`,
      { comments: payload.comments, reasons: [payload.reasons] }
    );
    return;
  },
  fetchReviewerExchangeToken: async (token: string | undefined) => {
    const response = await AxiosAccessTokenClientJwt.post<OmAdminTokenResponse>(
      `${ORDER_MANAGER_API_WS_URL}/reviewer-exchange/`,
      snakecaseKeys({ token }, { deep: true })
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOmAdminOrderItem: async (orderItemId: number) => {
    const response = await AxiosAccessTokenClientJwt.get<OmAdminOrderItem[]>(
      `${ORDER_MANAGER_REVIEW_API_URL}/order-items/?id=${orderItemId}`
    );

    const orderItem = response.data.find(
      (item: OmAdminOrderItem) => item.id === orderItemId
    );

    if (orderItem) {
      const camelcaseOrderItem = camelcaseKeys(orderItem, { deep: true });
      if (camelcaseOrderItem?.valueReportLink) {
        camelcaseOrderItem.valueReportLink = convertUrlToPrPreview(
          camelcaseOrderItem.valueReportLink
        );
      }
      return camelcaseOrderItem;
    }
    throw new Error(`[Order Item] No Order Item found for ID: ${orderItemId}`);
  },
  fetchOmAdminOrderItemPdfDownload: async (
    orderItemId: number,
    orderId: number
  ) => {
    const response = await AxiosAccessTokenClientJwt.get<OrderItemPdfDownload>(
      `${ORDER_MANAGER_REVIEW_API_URL}/value-report-orders/${orderId}/items/${orderItemId}/pdfdownload/value_report`
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOmAdminOrderItemActivity: async (orderItemId: number) => {
    const response = await AxiosAccessTokenClientJwt.get<OrderItemActivity[]>(
      `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/activity/`
    );

    const camelCaseData = camelcaseKeys(response.data, { deep: true });
    camelCaseData.forEach((item: OrderItemActivity) => {
      const { metadata } = item;
      item.eventType = snakeCaseToCamelCase(item.eventType);
      metadata.title = snakeCaseToCamelCase(metadata.title);
    });

    return camelcaseKeys(camelCaseData, { deep: true });
  },
  fetchOmAdminOrderItemValuationHistory: async (orderItemId: number) => {
    const response = await AxiosAccessTokenClientJwt.get<
      OrderItemValuationHistory[]
    >(
      `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/valuation-history/`
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOmAdminOrderItemInspectionForm: async (inspectionId: number) => {
    const response = await AxiosAccessTokenClientJwt.get<OrderItemForm>(
      `${ORDER_MANAGER_REVIEW_API_URL}/inspections/review/${inspectionId}/edit/`
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  fetchOmAdminOrderItemCommentById: async (
    orderItemId: number,
    commentId?: number
  ) => {
    const response =
      await AxiosAccessTokenClientJwt.get<OrderItemActivityCommentPayload>(
        `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/comments/${commentId}/`
      );

    return camelcaseKeys(response.data, { deep: true });
  },
  updateOmAdminOrderItemMessageActionRequired: async (
    orderItemActivityActionRequiredPayload: OrderItemActivityActionRequiredPayload
  ) => {
    const { xmlMessageId, comment, orderItemId, commentId } =
      orderItemActivityActionRequiredPayload;
    try {
      const response =
        await AxiosAccessTokenClientJwt.post<OmAdminActivityCommentResponse>(
          `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/comments/${commentId}/`,
          {
            xml_message_id: xmlMessageId,
            is_action_required: false,
            comment: comment,
            type: 'XmlMessage',
          }
        );
      return camelcaseKeys(response.data, { deep: true });
    } catch (error) {
      throw new Error(`No response from action required updated ${error}`);
    }
  },
  submitOmAdminOrderItemInspectionForm: async (
    inspectionId: number,
    formData: FormData
  ) => {
    try {
      const response =
        await AxiosAccessTokenClientJwt.post<SubmitInspectionFormResponse>(
          `${ORDER_MANAGER_REVIEW_API_URL}/inspections/review/${inspectionId}/edit/`,
          formData
        );

      return response.data;
    } catch (error) {
      throw new Error(`No response from inspection form submission ${error}`);
    }
  },
  updateOmAdminOrderItemInspectionImage: async (
    orderItemInspectionImagePayload: OrderItemInspectionImagePayload
  ) => {
    const { id, label, notes } = orderItemInspectionImagePayload;
    try {
      const response =
        await AxiosAccessTokenClientJwt.patch<UpdateInspectionImageResponse>(
          `${ORDER_MANAGER_REVIEW_API_URL}/inspection-image/${id}/`,
          snakecaseKeys({
            id,
            label,
            notes,
          })
        );

      return camelcaseKeys(response.data, { deep: true });
    } catch (error) {
      throw new Error(`No response from inspection image update ${error}`);
    }
  },
  deleteOmAdminOrderItemInspectionImage: async (id: number) => {
    try {
      await AxiosAccessTokenClientJwt.delete(
        `${ORDER_MANAGER_REVIEW_API_URL}/inspection-image/${id}/`,
        snakecaseKeys({
          id,
        })
      );
    } catch (error) {
      throw new Error(`No response from inspection image removal ${error}`);
    }
  },
  removeOmAdminOrderItemComps: async (
    orderItemId: number,
    hcAddressIds: number[]
  ) => {
    try {
      await AxiosAccessTokenClientJwt.post(
        `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/remove-comps/`,
        hcAddressIds
      );
    } catch (error) {
      throw new Error(`No response from comps removal ${error}`);
    }
  },
  createOmAdminOrderItemActivityLogNewMessage: async (
    activityLogNewMessagePayload: OmAdminActivityLogNewMessagePayload
  ) => {
    const { orderItemId, comment } = activityLogNewMessagePayload;
    try {
      const response =
        await AxiosAccessTokenClientJwt.post<OrderItemActivityCommentPayload>(
          `${ORDER_MANAGER_REVIEW_API_URL}/order-items/${orderItemId}/comment_create/`,
          snakecaseKeys({
            comment,
          })
        );
      return camelcaseKeys(response.data, { deep: true });
    } catch (error) {
      throw new Error(`No response from new message creation ${error}`);
    }
  },
  fetchAgileOpsOrganizations: async (
    params: { search: string | null },
    signal?: AbortSignal
  ) => {
    if (!params.search) {
      return null;
    }
    const response =
      await AxiosAccessTokenClientJwt.get<OrderItemOrganizations>(
        `${ORDER_MANAGER_REVIEW_API_URL}/orgs/`,
        {
          params: snakecaseKeys(params),
          signal,
        }
      );

    return camelcaseKeys(response.data.objects, { deep: true });
  },
};
