import { APP_CONFIG_AGILE_SUITE, APP_CONFIG_PEXP } from '@hcs/hc-products';
import { AppConfig } from '@hcs/types';

import { APP_CONFIG_AGILE_OPS_COMMAND } from './agile-ops';

export const PARTNER_TOOLS_APPS: AppConfig[] = [
  APP_CONFIG_AGILE_OPS_COMMAND,
  APP_CONFIG_AGILE_SUITE,
  APP_CONFIG_PEXP,
];
