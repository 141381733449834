import React from 'react';

import { TableCell } from '@hcs/design-system';
import { TableHeaderCell } from '@hcs/design-system';
import {
  OmAdminOrderItem,
  TableCellProps,
  TableHeaderCellProps,
} from '@hcs/types';
import { formatMissing, NULL_VALUE, ORDER_ITEM_STATUSES } from '@hcs/utils';

import { DisplayProps } from '.';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  return (
    <div data-hc-name={`${dataHcName}-status`}>
      {orderItem
        ? formatMissing(ORDER_ITEM_STATUSES[orderItem.status])
        : NULL_VALUE}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Status</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_STATUS_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Status',
};
