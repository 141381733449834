import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { formatNumber, NULL_VALUE } from '@hcs/utils';

import { DisplayProps } from '.';

import styles from '../OmAdminOrderItemsTable.module.css';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  return (
    <div data-hc-name={`${dataHcName}-qc-flags`}>
      {orderItem?.qcFlagsUnresolvedCount ? (
        <span data-hc-name={`${dataHcName}-flags`} className={styles.Flags}>
          {formatNumber(orderItem.qcFlagsUnresolvedCount)}
        </span>
      ) : (
        NULL_VALUE
      )}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Flags</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_QC_FLAGS_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Flags',
};
