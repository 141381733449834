import React from 'react';

import { TextButton } from '@hcs/design-system';
import { DirectionalChevron } from '@hcs/design-system';
import { useOmAdminOrderItem } from '@hcs/order-manager-admin';

import { useNavigateBackToTable } from '../../hooks/useNavigateBackToTable';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-items-go-back-nav';
export const GoBackNav = ({ orderItemId }: Props) => {
  const { data: orderItem } = useOmAdminOrderItem(orderItemId);
  const { valueReportReviewPending, inspectionReviewPending } = orderItem || {};
  const navigateBackToTable = useNavigateBackToTable([
    valueReportReviewPending,
    inspectionReviewPending,
  ]);

  return (
    <div data-hc-name={dataHcName}>
      <TextButton
        dataHcName={`${dataHcName}-back-button`}
        onClick={navigateBackToTable}
        icon={
          <DirectionalChevron
            dataHcName={`${dataHcName}-DirectionalChevron`}
            direction="left"
            smallIcon
          />
        }
      >
        Go Back
      </TextButton>
    </div>
  );
};
