import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';

import { OmValuationHistoryChartConfig } from '@hcs/types';
import { formatMoneyAbbrev, NULL_VALUE } from '@hcs/utils';

import styles from './OmAdminOrderItemValuationHistory.module.css';

interface Props {
  chartConfig: OmValuationHistoryChartConfig;
}

const dataHcName = 'om-admin-order-item-valuation-history';
export const ValuationHistoryChart = ({ chartConfig }: Props) => {
  const { chartData, xAxisTickValues } = chartConfig;
  if (!chartData[0]?.data.length) {
    return <p>More than 1 completed valuation needed to render chart</p>;
  }
  return (
    <>
      <h4>Valuation History</h4>
      <div data-hc-name={dataHcName} className={styles.ValuationHistoryChart}>
        <ResponsiveLine
          animate={true}
          data={chartData}
          axisLeft={{
            tickSize: 5,
            tickPadding: 20,
            format: formatMoneyAbbrev,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 20,
            tickRotation: -75,
            tickValues: xAxisTickValues,
            format: (v) => (v ? format(v, 'MM/yy') : NULL_VALUE),
          }}
          yFormat=" >-$1,"
          xFormat={(v) => format(new Date(v), 'MM/dd/yy')}
          margin={{ top: 40, right: 40, bottom: 80, left: 80 }}
          xScale={{
            type: 'time',
          }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          pointSize={8}
          pointColor="transparent"
          pointBorderWidth={2}
          pointBorderColor="#FDB813"
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>
    </>
  );
};
