import React, { useEffect, useState } from 'react';

import { Checkbox } from '@hcs/design-system';

import { useOmAdminOrderItemDisabled } from '../../hooks/useOmAdminOrderItemDisabled';

interface Props {
  orderItemId: number;
  toggleChange: (v: boolean, hcAddressId: number) => void;
  hcAddressId: number;
  removableComps: number[] | null;
}

const dataHcName = 'comp-checkbox';
export const CompCheckbox = ({
  orderItemId,
  toggleChange,
  hcAddressId,
  removableComps,
}: Props) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (removableComps?.includes(hcAddressId)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [removableComps]);

  const { data: orderItemDisabled } = useOmAdminOrderItemDisabled(orderItemId);

  return (
    <Checkbox
      dataHcName={`${dataHcName}-select-all`}
      checked={checked}
      disabled={orderItemDisabled?.isNotAssignee}
      onChange={(v) => {
        toggleChange(v, hcAddressId);
      }}
    />
  );
};
