import React from 'react';

import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import {
  OmValuationHistoryItem,
  OmValuationHistoryTableConfig,
} from '@hcs/types';

import { VALUATION_HISTORY_ITEM_COLUMNS_DEFAULT_ORDER } from '../../constants/orderItem.constants';

import { VALUATION_HISTORY_TABLE_CELLS_CONFIG } from './ValuationHistoryTableDataCells';

interface Props {
  tableConfig: OmValuationHistoryTableConfig;
}

const dataHcName = 'om-admin-order-item-valuation-history-table';
export const ValuationHistoryTable = ({ tableConfig }: Props) => {
  return (
    <Table dataHcName={dataHcName}>
      <TableHeader key={`${dataHcName}-header-row`} sticky>
        {tableConfig?.tableHeaders.map((item: string, idx: number) => (
          <TableHeaderCell key={`${item}-${idx}-header`}>
            {item}
          </TableHeaderCell>
        ))}
      </TableHeader>
      {tableConfig?.tableRows.map((item: OmValuationHistoryItem) => {
        return (
          <TableRow key={item.download.orderId}>
            {VALUATION_HISTORY_ITEM_COLUMNS_DEFAULT_ORDER.map((option) => {
              const { ContentCell } =
                VALUATION_HISTORY_TABLE_CELLS_CONFIG[option];
              return (
                <ContentCell
                  key={`cell-${option}`}
                  valuationHistoryItem={item}
                />
              );
            })}
          </TableRow>
        );
      })}
    </Table>
  );
};
