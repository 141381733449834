import { AgileEvaluationIcon } from '@hcs/design-system';
import { AppSlugs, CapabilityApplications, Roles } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_AGILE_OPS_COMMAND: AppConfig = {
  name: 'AgileOps Command Center',
  appSlug: AppSlugs.AgileOpsCommandCenter,
  applicationKey: CapabilityApplications.InternalAgileOps,
  Icon: AgileEvaluationIcon,
  rootPath: '/agile-ops',
  descriptionLong: 'Quality Control Tools for the HC Agile Ops Team',
  descriptionShort: 'Quality Control Tools for the HC Agile Ops Team',
  feOnlyApp: true,
  requiredRole: [Roles.AgileOps, Roles.Broker],
};
export enum VIEW_PATHS_AGILE_OPS_COMMAND {
  ALL_ADDRESSES = 'all-addresses',
  REVIEW_QUEUE = 'review-queue',
  ORDER_ITEM = 'order-item/:orderItemId',
}
