import React, { useState } from 'react';

import { ErrorIcon } from '@hcs/design-system';

import { useOmAdminOrderItemActivity } from '../../hooks/useOmAdminOrderItemActivity';
import { TabsContentWrapper } from '../OmAdminOrderItemTabSection/TabsContentWrapper';

import { ActivityItems } from './ActivityItems';
import { ActivityItemDropdown } from '.';

import styles from './OmAdminOrderItemActivityLog.module.css';

interface Props {
  orderItemId: number;
}

const dataHcName = 'om-admin-order-item-activity-log';

export const OmAdminOrderItemActivityLog = ({ orderItemId }: Props) => {
  const [selected, setSelected] = useState<string | undefined>('selectAll');
  const {
    data: activities,
    isFetching,
    isSuccess,
    isError,
  } = useOmAdminOrderItemActivity(orderItemId, selected);

  const handleSelect = (type: string | null) => {
    if (type) {
      setSelected(type);
    }
  };

  return (
    <TabsContentWrapper dataHcName={dataHcName}>
      {isSuccess && (
        <>
          <ActivityItemDropdown
            selected={selected}
            handleSelect={handleSelect}
          />
          <ActivityItems
            activities={activities}
            orderItemId={orderItemId}
            isFetching={isFetching}
          />
        </>
      )}
      {isError && (
        <div
          className={styles.ErrorRow}
          data-hc-name={`${dataHcName}-error-row`}
        >
          <ErrorIcon
            className={styles.ErrorIcon}
            size="sm"
            dataHcName={`${dataHcName}-error-icon`}
          />
          Error: Activity Items Unavailable
        </div>
      )}
    </TabsContentWrapper>
  );
};
