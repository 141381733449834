import React from 'react';

import { useOmAdminOrderItem } from '../../hooks/useOmAdminOrderItem';
import { OmAdminOrderItemPhotoCarousel } from '../OmAdminOrderItemPhotoCarousel';
import { OmAdminOrderItemTabSection } from '../OmAdminOrderItemTabSection';

interface Props {
  orderItemId: number;
}

export const OmAdminOrderItemSidebar = ({ orderItemId }: Props) => {
  const { data: orderItem } = useOmAdminOrderItem(orderItemId);

  return orderItem ? (
    <>
      <OmAdminOrderItemPhotoCarousel orderItemId={orderItemId} />
      <OmAdminOrderItemTabSection orderItemId={orderItemId} />
    </>
  ) : null;
};
