import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { LoadingSpinner } from '@hcs/design-system';
import { CompDetailsTypes } from '@hcs/types';
import { formatMoneyAbbrev } from '@hcs/utils';

import { useOmAdminCompsDetails } from '../../hooks/useOmAdminCompsDetails';

interface Props {
  orderItemId: number;
  compDetailsType: CompDetailsTypes;
}

const dataHcName = 'om-admin-order-item-comps-details-chart';
export const CompsDetailsChart = ({ orderItemId, compDetailsType }: Props) => {
  const { chartData, avmVal, avmMin, avmMax } = useOmAdminCompsDetails(
    orderItemId,
    compDetailsType
  );

  if (!avmVal) {
    return <LoadingSpinner dataHcName={`${dataHcName}-spinner`} />;
  }
  return (
    <ResponsiveBar
      data={chartData}
      animate={true}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Property',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      indexBy="property"
      valueScale={{
        type: 'linear',
        min: avmVal * 0.5,
        max: avmVal * 1.5,
        clamp: true,
      }}
      keys={['currentValue', 'soldPrice']}
      groupMode="grouped"
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      padding={0.25}
      innerPadding={0}
      isInteractive={false}
      indexScale={{ type: 'band', round: true }}
      axisLeft={{
        tickValues: 3,
        format: (val) => formatMoneyAbbrev(val, { fixed: 2 }),
      }}
      enableLabel={false}
      markers={[
        {
          axis: 'y',
          value: Number(avmMax),
          lineStyle: {
            stroke: 'var(--neutral-dark-30)',
            strokeWidth: 1,
            strokeDasharray: '4 4',
          },
          legend: `${formatMoneyAbbrev(avmMax, { fixed: 2 })}`,
          legendPosition: 'top-right',
          textStyle: {
            fontSize: 10,
          },
        },
        {
          axis: 'y',
          value: Number(avmMin),
          lineStyle: {
            stroke: 'var(--neutral-dark-30)',
            strokeWidth: 1,
            strokeDasharray: '4 4',
          },
          legend: `${formatMoneyAbbrev(avmMin, { fixed: 2 })}`,
          legendPosition: 'top-right',
          textStyle: {
            fontSize: 10,
          },
        },
      ]}
      colors={({ id, data }) => {
        return id === 'currentValue'
          ? data[`currentValueColor`]
          : data[`soldPriceColor`];
      }}
      legendLabel={(datum) => {
        return datum.id === 'currentValue' ? 'Current Value' : 'Sold Price';
      }}
      legends={[
        {
          dataFrom: 'keys',
          data: [
            {
              id: 'Subject',
              label: 'Subject',
              color: 'var(--secondary-10)',
            },
            {
              id: 'Current Value',
              label: 'Current Value',
              color: 'var(--neutral-dark-10)',
            },
            {
              id: 'Sold Price',
              label: 'Sold Price',
              color: 'var(--neutral-dark-40)',
            },
          ],
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -32,
          itemsSpacing: 10,
          itemWidth: 90,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 12,
          symbolShape: 'circle',
        },
      ]}
    />
  );
};
