import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OmAdminActivityLogNewMessagePayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_OM_ADMIN_ACTIVITY } from './useOmAdminOrderItemActivity';

export interface CreateActivityLogNewMessageResponse {
  comment: string;
}

export const useCreateOmAdminOrderItemActivityLogNewMessage = (
  options?: UseMutationOptions<
    CreateActivityLogNewMessageResponse,
    AxiosError<{ message: string }>,
    OmAdminActivityLogNewMessagePayload,
    void
  >
) => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    CreateActivityLogNewMessageResponse,
    AxiosError<{ message: string }>,
    OmAdminActivityLogNewMessagePayload,
    void
  >(
    async (activityLogNewMessagePayload) => {
      return await OrderManagerReviewApi.createOmAdminOrderItemActivityLogNewMessage(
        activityLogNewMessagePayload
      );
    },
    {
      ...options,
      onSuccess: (response, variables) => {
        options?.onSuccess?.(response, variables);
        queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_ACTIVITY]);
        toastOpen({
          type: 'success',
          title: 'Successfully created activity log new message',
        });
      },
      onError: (error, variables) => {
        options?.onError?.(error, variables);
        toastOpen({
          type: 'error',
          title: `Failed to Create Activity Log New Message, ${error.response?.data.message}`,
        });
      },
    }
  );
};
