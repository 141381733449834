import React from 'react';

import {
  Dialog,
  DIALOG_ACTIONS_PORTAL_ID,
  DialogProps,
} from '@hcs/design-system';

import {
  ActivityLogNewMessageProps,
  OmAdminOrderItemActivityLogNewMessage,
} from './OmAdminOrderItemActivityLogNewMessage';

import styles from './OmAdminOrderItemActivityLogNewMessage.module.css';

export interface OmAdminOrderItemActivityLogNewMessageDialogProps {
  activityLogNewMessageProps: ActivityLogNewMessageProps;
  dialogProps: Omit<DialogProps, 'dataHcName' | 'children'>;
}
const dataHcName = 'om-admin-order-item-activity-log-new-message-dialog';
export const OmAdminOrderItemActivityLogNewMessageDialog = ({
  activityLogNewMessageProps,
  dialogProps,
}: OmAdminOrderItemActivityLogNewMessageDialogProps) => {
  return (
    <Dialog
      {...dialogProps}
      title="New Message"
      dataHcName={dataHcName}
      type="small"
      theme={{
        DialogContent: styles.DialogContent,
      }}
    >
      <OmAdminOrderItemActivityLogNewMessage
        {...activityLogNewMessageProps}
        onCancel={() => {
          activityLogNewMessageProps.onCancel?.();
          dialogProps.onClose();
        }}
        onSuccess={() => {
          activityLogNewMessageProps.onSuccess?.();
          dialogProps.onClose();
        }}
        actionButtonsPortalId={DIALOG_ACTIONS_PORTAL_ID}
      />
    </Dialog>
  );
};
