import React, { useState } from 'react';

import { TextArea } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { OrderItemUpdatePayload, UpdateOptions } from '@hcs/types';

import { useRegenerateOrderItemValueReport } from '../../hooks/useRegenerateOrderItemValueReport';

import { AgileOpsUpdateDropdown } from './AgileOpsUpdateDropdown';

import styles from './AgileOpsOrderItemUpdate.module.css';

export interface UpdateProps {
  actionButtonsPortalId?: string;
  className?: string;
  onClose?: VoidFunction;
  valueReportId: number | null;
}
const dataHcName = 'agile-ops-order-item-update';
export const AgileOpsOrderItemUpdate = ({
  actionButtonsPortalId,
  className,
  onClose,
  valueReportId,
}: UpdateProps) => {
  const [comments, setComments] = useState('');
  const [updateReason, setUpdateReason] = useState<UpdateOptions | null>(null);
  const regenerateOrderItemValueReportMutation =
    useRegenerateOrderItemValueReport();

  const handleSubmit = () => {
    if (updateReason && valueReportId) {
      const updatePayload: OrderItemUpdatePayload = {
        valueReportId,
        comments,
        reasons: updateReason,
      };
      regenerateOrderItemValueReportMutation.mutate(updatePayload);
      onClose?.();
    }
  };

  return (
    <>
      <AgileOpsUpdateDropdown
        onSelect={setUpdateReason}
        className={className}
        value={updateReason}
      />
      <TextArea
        value={comments}
        dataHcName={`${dataHcName}-comments-text-area`}
        onChange={setComments}
        className={styles.TextArea}
        placeholder="Comments"
      />
      <ActionButtons
        portalIdRender={actionButtonsPortalId}
        dataHcName={`${dataHcName}-actions`}
        actions={[
          {
            dataHcName: `${dataHcName}-close`,
            label: 'Cancel',
            onClick: onClose,
            secondary: true,
          },
          {
            dataHcName: `${dataHcName}-submit`,
            label: 'Update',
            disabled:
              regenerateOrderItemValueReportMutation.isLoading || !updateReason,
            loading: regenerateOrderItemValueReportMutation.isLoading,
            onClick: handleSubmit,
          },
        ]}
      />
    </>
  );
};
