import { useNavigate } from 'react-router';

import { APP_CONFIG_AGILE_OPS_COMMAND } from '@hcs/hc-internal-apps';
import { VIEW_PATHS_AGILE_OPS_COMMAND } from '@hcs/hc-internal-apps';

export const useNavigateBackToTable = (
  currentReviews?: (boolean | null | undefined)[]
) => {
  const navigate = useNavigate();
  const hasReviewsPending = currentReviews?.some(
    (reviewPending) => reviewPending
  );

  if (hasReviewsPending) {
    return () =>
      navigate(
        `${APP_CONFIG_AGILE_OPS_COMMAND.rootPath}/${VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE}`,
        {}
      );
  } else {
    return () =>
      navigate(
        `${APP_CONFIG_AGILE_OPS_COMMAND.rootPath}/${VIEW_PATHS_AGILE_OPS_COMMAND.ALL_ADDRESSES}`,
        {}
      );
  }
};
